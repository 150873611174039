import {Box, createTheme, Grid, Paper, Stack, Typography, useTheme} from "@mui/material";
import {ThemeProvider} from "@mui/system";
import {
	CalendarCardWithoutLogic,
	MatchListElementFirestoreDocument,
	useIsMobileContext,
	useTimeManagementContext
} from "@nitra/nkmb-card-components";
import {areDatesEqual} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/AreDatesEqual";
import {CalendarCardDay} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/CalendarCardDay";
import {getCalendar} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/GetCalendar";
import {getMonthName} from "@nitra/nkmb-card-components/contexts/timeContextHelpers/GetMonthName";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

/**
 * Element containing calendar cards for each month for the whole season
 * @constructor
 */
export function YearOverviewCalendar() {
	const {year, month, handleGoToMonth} = useTimeManagementContext()

	const [autumnPartYear, setAutumnPartYear] = useState<number>(0)
	const [springPartYear, setSpringPartYear] = useState<number>(0)

	const isMobile = useIsMobileContext()
	const theme = useTheme()

	useEffect(() => {
		if (month < 5) {
			setAutumnPartYear(year - 1)
			setSpringPartYear(year)
		} else {
			setAutumnPartYear(year)
			setSpringPartYear(year + 1)
		}
	}, [year, month])

	const months = [
		[5, 6, 7, 8, 9, 10, 11],
		[0, 1, 2, 3, 4],
	];

	const changedPaperBackgroundTheme = createTheme(theme, {
		palette: {
			background: {
				default: theme.palette.background.paper,
			}
		}
	})

	return (
		<Grid container spacing={3}>
			{months.map((part, index) =>
				part.map((monthValue) => (
					<Grid item xs sm={6} md={4} lg={3} key={`${index}-${monthValue}`}
					      onClick={() => handleGoToMonth(monthValue < 5 ? springPartYear : autumnPartYear, monthValue)}>
						<ThemeProvider theme={monthValue == month ? theme : changedPaperBackgroundTheme}>
							{isMobile
								? <TinyCalendar
									month={monthValue}
									year={index === 0 ? autumnPartYear : springPartYear}
								/>
								: <CalendarCardWithoutLogic
									month={monthValue}
									year={index === 0 ? autumnPartYear : springPartYear}
								/>
							}
						</ThemeProvider>
					</Grid>
				))
			)}
		</Grid>
	);
}

function TinyCalendar({
	                      month, year
                      }: {
	month: number,
	year: number,
}) {
	const {month: activeMonth} = useTimeManagementContext()

	const core = (
		<>
			<Typography variant={"podnaslovXS"}>{getMonthName(month, false)}</Typography>
			<Stack>
				{getCalendar(month, year).map((week, index) =>
					<Box key={index}>
						<Stack key={index} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
							{week.map((day, index) =>
								<TinyDayBox key={index} day={day} prominentMonth={month}/>
							)}
						</Stack>
					</Box>
				)}
			</Stack>
		</>
	)

	if (activeMonth === month) {
		return (
			<Paper sx={{p: 1}}>
				{core}
			</Paper>
		)
	} else {
		return (
			<Box sx={{p: 1}}>
				{core}
			</Box>
		)
	}
}

function TinyDayBox({
	                    day,
	                    prominentMonth,
                    }: {
	day: CalendarCardDay,
	prominentMonth: number,
}) {
	const navigate = useNavigate()

	const {
		matches,
	} = useTimeManagementContext()

	const [matchingMatch, setMatchingMatch] = useState<MatchListElementFirestoreDocument | undefined>(undefined)
	const [backgroundColor, setBackgroundColor] = useState("transparent")
	const [color, setColor] = useState("unset")

	const theme = useTheme()

	useEffect(() => {
		const match = matches.find(match => areDatesEqual(day.date, match.date))
		setMatchingMatch(match)
		if (match && day.date.getMonth() === prominentMonth) {
			if (match.homeTeamName.toLowerCase().includes("maribor")) {
				setBackgroundColor(theme.palette.primary.main)
				setColor(theme.palette.primary.contrastText)
			} else {
				setBackgroundColor(theme.palette.secondary.main)
				setColor("unset")
			}
		} else {
			setBackgroundColor("transparent")
			setColor("unset")
		}

	}, [matches, day])

	function handleClick() {
		if (matchingMatch) {
			const {buyTicketLink, reportId, liveTickerKey} = matchingMatch

			if (reportId) {
				navigate("/news/" + reportId)
			} else if (liveTickerKey) {
				navigate("/live-ticker/" + liveTickerKey)
			} else if (buyTicketLink) {
				window.open(buyTicketLink, "_blank")
			}
		}
	}

	return (
		<Box
			onClick={handleClick}
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				width: "14px",
				height: "14px",
				borderRadius: "50%",
				p: 0,
				m: "2px",
				color: color,
				backgroundColor: backgroundColor,
			}}>
			<Typography
				variant={"podnapis"}
				sx={{opacity: day.date.getMonth() === prominentMonth ? 1 : 0.0}}
			>{day.date.getDate()}</Typography>
		</Box>
	)
}
