import {ButtonTabNavigatorWithRouter} from "@nitra/nkmb-card-components";
import React from "react";
import {DesktopClubStatistics} from "views/club/statistics/desktop/club/DesktopClubStatistics";
import {DesktopPlayerStatistics} from "views/club/statistics/desktop/player/DesktopPlayerStatistics";

const tabs = [{
	path: "standings", label: "Klub", component: <DesktopClubStatistics/>
}, {
	path: "players", label: "Igralci", component: <DesktopPlayerStatistics/>
}]

export function DesktopStatistics() {
	return (
		<ButtonTabNavigatorWithRouter tabs={tabs} sx={{px: 0, pt: 2}} blackVersion={true}/>
	)
}
