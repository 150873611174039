import {CompetitionStatisticsPlayerCompetitionFirestoreDocument} from "../../../models";
import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import {alpha, Stack, SvgIcon, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import {getCompetitionNameFromType} from "../../../utilities/enumToName";
import React from "react";
import {NkmbIconChampionship, NkmbIconCup, NkmbIconUcl} from "@nitra/nkmb-theme";

export function CompetitionStatisticsClubPlayersSingleCompetitionRow(
	props: {
		open: boolean,
		competitionStats: CompetitionStatisticsPlayerCompetitionFirestoreDocument,
	}
) {
	const {open} = props;
	const {competitionType, goals, assists, yellowCards, redCards, minutes, matches} = props.competitionStats;
	const isMobile = useIsMobileContext()
	const theme = useTheme();

	return open
		? (<TableRow sx={{
				color: "#676767",
				backgroundColor: isMobile ? alpha(theme.palette.text.primary, 0.03) : theme.palette.background.default,
				'& .MuiTableCell-root': {
					borderColor: isMobile ? theme.palette.background.default : "unset",
				},
			}}>
				<TableCell colSpan={isMobile ? 2 : 3}
				           align={isMobile ? "right" : "left"}
				>
					{isMobile ?
						<Stack direction={"row"} alignItems={"center"} justifyContent={"end"}>
							<Typography
								variant={isMobile ? "bodySBold" : "bodyS"}>{getCompetitionNameFromType(competitionType)}</Typography>
							<SvgIcon component={
								competitionType === ECompetitionType.FIRST_LEAGUE ? NkmbIconChampionship
									: competitionType === ECompetitionType.CUP ? NkmbIconCup
										: NkmbIconUcl}
							         sx={{width: 24, height: 24, color: "#676767"}}
							         viewBox={"0 0 100 100"}/>
						</Stack>
						:
						<Typography
							variant={isMobile ? "bodySBold" : "bodyS"}>{getCompetitionNameFromType(competitionType)}</Typography>
					}
				</TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyS"}>{goals}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyS"}>{assists}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyS"}>{yellowCards}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyS"}>{redCards}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyS"}>{minutes}</Typography></TableCell>
				{!isMobile &&
                    <TableCell align={"center"}><Typography
                        variant={isMobile ? "bodySBold" : "bodyS"}>{matches}</Typography></TableCell>
				}
			</TableRow>
		) :
		(<TableRow></TableRow>)
}