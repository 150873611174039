import {Stack} from "@mui/material";
import Banner from "components/Banner";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getCompetitionHistorySeasonResult} from "firestore/BaseFirestore";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";

export function ClubTrophy() {
	const {key} = useParams()

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [title, setTitle] = useState("");
	const [image1440x680url, setImage1440x680url] = useState("");

	useEffect(() => {
		if (key === undefined) {
			return;
		}
		getData(key)
	}, []);

	const getData = (key: string) => {
		getCompetitionHistorySeasonResult(key).then((result) => {
			setTitle(result.season);
			setImage1440x680url(result.image1440x680url ?? "");
			setContent(result.content);
		})
	}

	return (
		<Stack>
			<Banner title={title} imageUrl={image1440x680url} isLeftAligned={false}/>
			<ContentContainer content={content}/>
		</Stack>
	)
}
