import {
	BreakpointContextProvider,
	CurrentSeasonContextProvider,
	DevelopmentContextProvider,
	FooterColorModeContextProvider,
	IosContextProvider,
	IsMobileContextProvider,
	PaperBackgroundContextProvider,
	ViewportWidthContextProvider
} from "@nitra/nkmb-card-components";
import {PropsWithChildren} from "react";
import {AppBarHeightContextProvider} from "./AppBarHeightContext";


export function ContextProviders(props: PropsWithChildren<{}>) {
	return (
		<BreakpointContextProvider>
			<IsMobileContextProvider>
				<PaperBackgroundContextProvider>
					<FooterColorModeContextProvider>
						<DevelopmentContextProvider>
							<ViewportWidthContextProvider>
								<CurrentSeasonContextProvider>
									<AppBarHeightContextProvider>
										<IosContextProvider>
											{props.children}
										</IosContextProvider>
									</AppBarHeightContextProvider>
								</CurrentSeasonContextProvider>
							</ViewportWidthContextProvider>
						</DevelopmentContextProvider>
					</FooterColorModeContextProvider>
				</PaperBackgroundContextProvider>
			</IsMobileContextProvider>
		</BreakpointContextProvider>
	);
}
