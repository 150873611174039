import {Stack} from "@mui/material";
import React from "react";

import {Facebook, Instagram, Twitter, Youtube} from "../Socials";
import {FlagSvn} from "@nitra/nkmb-theme";

export function MenuDrawerSocials() {
	return (
		<Stack direction={"row"}
		       justifyContent={"space-between"}
		       alignItems={"center"}
		>
			<FlagSvn viewBox={"0 0 100 100"} width={48} height={48}/>
			<Facebook/>
			<Instagram/>
			<Twitter/>
			<Youtube/>
		</Stack>
	);
}
