import {CardWithShadow, MatchListElementFirestoreDocument} from "@nitra/nkmb-card-components";
import {Stack} from "@mui/material";
import {MatchContainerTimeAndDate} from "./MatchContainerTimeAndDate";
import {MatchContainerRightColumn} from "./MatchContainerRightColumn";
import {MatchContainerCenterColumn} from "./MatchContainerCenterColumn";

import {useCompetitionViewContext} from "../../views/competitions/common/CompetitionContext";

export function SingleMatchContainer(props: { match: MatchListElementFirestoreDocument }) {
	const {match} = props;

	const {currentRef, firstRef, firstMatchKey, currentMatchKey} = useCompetitionViewContext();

	function isFirst() {
		return match.key === firstMatchKey;
	}

	function isCurrent() {
		return match.key === currentMatchKey;
	}

	return (
		<CardWithShadow
			sx={{
				px: 0,
				py: 0,
			}}
			ref={isFirst() ? firstRef : isCurrent() ? currentRef : null}
			cardHeightOverride={120}
			disableOnHoverActions={true}
		>
			<Stack
				sx={{
					height: "100%",
					px: 3,
					py: "12px"
				}}
				direction={"row"}
				justifyContent={"space-between"}
				alignItems="stretch"
			>
				<MatchContainerTimeAndDate match={match}/>
				<MatchContainerCenterColumn match={match}/>
				<MatchContainerRightColumn match={match}/>
			</Stack>
		</CardWithShadow>
	);
}

