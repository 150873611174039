import {Stack} from "@mui/material";
import {Redirect} from "@nitra/nkmb-card-components";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {TeamNavigationBar} from "./navigationBar/TeamNavigationBar";
import PlayerDetailsPage from "./playerDetailsPage/PlayerDetailsPage";
import {TeamSquadView} from "./TeamSquadView";
import {SupportStaffView} from "./SupportStaffView";

const TeamRouter = () => {
	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{
				display: "flex",
				flexGrow: 1,
				width: "100%",
			}}
		>
			<TeamNavigationBar/>
			<Stack sx={{mx: 3, width: "100%"}} direction={"column"}>
				<Routes>
					<Route path={"/"} element={<Redirect to={"players"}/>}/>
					<Route path={"players"} element={<TeamSquadView/>}/>
					<Route path={"staff"} element={<SupportStaffView/>}/>
					<Route path={"player/:key/*"} element={<PlayerDetailsPage/>}/>
				</Routes>
			</Stack>
		</Stack>

	)
}


export default TeamRouter;

