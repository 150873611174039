import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import Banner from "../../components/Banner";
import {VipTexts} from "./VipTexts";
import {INNER_MAX_WIDTH, INNER_PADDING} from "../../App";
import {VipButton} from "./VipButton";
import {BasicVipElement} from "./BasicVipElement";
import {VipPhotoGallery} from "./VipPhotoGallery";
import React from "react";
import {Todo} from "../../components/Todo";
import {DoubleVideoCard, ShareArea, useDevelopmentContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

function VipSkybox() {
	const theme = useTheme();

	const {showTodos} = useDevelopmentContext();
	const {isIos} = useIosContext()

	return (
		<Stack
			alignItems={"center"}
			direction={"column"}
			sx={{
				display: "flex",
				flexGrow: 1,
				width: "100%"
			}}>
			<Banner
				title={VipTexts.skybox.banner.title}
				subtitle={VipTexts.skybox.banner.subtitle}
				imageUrl={"/images/hospitality/skybox/skybox_banner.png"}
			/>
			<Grid
				sx={{
					width: "100%",
					maxWidth: INNER_MAX_WIDTH,
					px: INNER_PADDING,
					mx: "auto",
					pt: {xs: 2, md: 4},
					flexGrow: 1,
					mt: 0,
					color: theme.palette.background.paper,
				}}
				spacing={2}
				container
			>
				<Grid item xs={12} md={6}>
					<DoubleVideoCard youTubeId={"mW-3r_zcFJo"}/>
				</Grid>
				<Grid item xs={12} md={6} sx={{
					pt: {xs: 2, md: 0},
				}}>
					<Typography variant={"h3"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{VipTexts.skybox.title1}</Typography>
					<Typography variant={"body1"} align={"justify"} sx={{mt: 2}}>{VipTexts.skybox.body1}</Typography>
				</Grid>
				<Grid item xs={12} sx={{mt: 4}}>
					<Typography variant={"h3"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}>{VipTexts.skybox.title2}</Typography>
					<Typography sx={{mt: 2}} align={"justify"} variant={"body1"}>{VipTexts.skybox.body2}</Typography>
				</Grid>
				<Todo label={"Tu se moremo dogovorit kateri vse gumbi so sploh smiselni"}/>
				<Grid item sx={{mt: 4, backgroundColor: showTodos ? "red" : null}} container>
					<VipButton
						label={"Natisni ponudbo"}
						navigateLink={"/"}
					/>
					<VipButton
						label={"Izbira sedeža"}
						href={"/"}
						sx={{ml: 2}}/>
					<ShareArea sx={{ml: 2}} shareUrl={"/"} brightText={true}/>
					<VipButton
						label={"hospitality@nkmaribor.com"}
						href={"mailto:hospitality@nkmaribor.com"}
						sx={{ml: "auto"}}/>
				</Grid>
				<VipPhotoGallery imageUrls={VipTexts.skybox.imageUrls}/>
				<Box sx={{
					position: "relative",
					mt: 4,
					py: 4,
				}}>
					<Box sx={{
						position: "absolute",
						top: 0,
						left: "-50vw",
						right: "-50vw",
						height: "100%",
						backgroundColor: "rgba(217, 217, 217, 0.1)",
					}}/>
					<BasicVipElement {...VipTexts.basic.vps}/>
				</Box>
			</Grid>
		</Stack>
	)
}

export default VipSkybox;
