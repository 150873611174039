import {Divider, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ScoreAssistCompetitionSelector} from "views/competitions/playerStatSidebar/ScoreAssistCompetitionSelector";
import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {MatchListElementFirestoreDocument} from "@nitra/nkmb-card-components";

export function CompetitionStatisticsClubStatsBlock() {
	const {competitionStatBlock} = useCompetitionStatisticsContext()

	const [highestWin, setHighestWin] = useState<MatchListElementFirestoreDocument | null>(null)
	const [winCountValue, setWinCountValue] = useState<string>("")
	const [lossCountValue, setLossCountValue] = useState<string>("")
	const [drawCountValue, setDrawCountValue] = useState<string>("")


	useEffect(() => {
		if (competitionStatBlock) {
			if (competitionStatBlock.highestWins.length > 0) {
				setHighestWin(competitionStatBlock.highestWins[0])
			}

			if (competitionStatBlock.matchCount !== undefined) {
				if (competitionStatBlock.winCount !== undefined) {
					setWinCountValue(`${competitionStatBlock.winCount} (${(competitionStatBlock.winCount / competitionStatBlock.matchCount * 100).toFixed(2)}%)`)
				} else {
					setWinCountValue("")
				}

				if (competitionStatBlock.lossCount !== undefined) {
					setLossCountValue(`${competitionStatBlock.lossCount} (${(competitionStatBlock.lossCount / competitionStatBlock.matchCount * 100).toFixed(2)}%)`)
				} else {
					setLossCountValue("")
				}

				if (competitionStatBlock.drawCount !== undefined) {
					setDrawCountValue(`${competitionStatBlock.drawCount} (${(competitionStatBlock.drawCount / competitionStatBlock.matchCount * 100).toFixed(2)}%)`)
				} else {
					setDrawCountValue("")
				}
			} else {
				setWinCountValue("")
				setLossCountValue("")
				setDrawCountValue("")
			}
		}
	}, [competitionStatBlock]);

	return (
		<Stack sx={{pt: 2}}>
			<ScoreAssistCompetitionSelector/>
			<Stack sx={{pt: 2}}>
				<StatRow label={"Št. tekem"} value={competitionStatBlock?.matchCount}/>
				<StatRow label={"Zmage"}
				         value={winCountValue}/>
				<StatRow label={"Porazi"}
				         value={lossCountValue}/>
				<StatRow label={"Neodločeno"}
				         value={drawCountValue}/>
				<StatRow
					label={"Najvišja zmaga"}
					value={!!highestWin ?
						highestWin.date.toLocaleString("sl-sl", {
							day: "2-digit",
							month: "2-digit",
							year: "numeric"
						}) : ""
					}
					secondRow={!!highestWin ? `${highestWin?.homeTeamName} - ${highestWin?.awayTeamName} ${highestWin?.score}` : ""}/>
				<StatRow label={"Dani goli"} value={competitionStatBlock?.goalsScored}/>
				<StatRow label={"Prejeti goli"} value={competitionStatBlock?.goalsConceded}/>
				<StatRow label={"Število 11-m"} value={competitionStatBlock?.penaltiesScored}/>
				<StatRow label={"Število avtogolov"} value={competitionStatBlock?.autoGoalsCount}/>
				<StatRow label={"Št. rumenih kartonov"} value={competitionStatBlock?.yellowCardCount}/>
				<StatRow label={"Št. rdečih kartonov"} value={competitionStatBlock?.redCardCount}/>
				<StatRow label={"Število igralcev*"} value={competitionStatBlock?.playerCount}/>
				<StatRow label={"Število gledalcev"} value={competitionStatBlock?.viewerCount}/>
				<StatRow label={"Pov. gledalcev/tekmo"} value={competitionStatBlock?.averageViewerCount}/>
				<StatRow
					label={"Največ gledalcev"}
					value={!!competitionStatBlock ? `${competitionStatBlock?.matchWithMostViewers?.viewerCount}, 
						${competitionStatBlock?.matchWithMostViewers?.date.toLocaleString("sl-sl", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric"
					})}` : ""}
					secondRow={!!competitionStatBlock ? `${competitionStatBlock?.matchWithMostViewers?.homeName} - ${competitionStatBlock?.matchWithMostViewers?.awayName} ${competitionStatBlock?.matchWithMostViewers?.homeScore}:${competitionStatBlock?.matchWithMostViewers?.awayScore}` : ""}/>
				<StatRow
					label={"Najmanj gledalcev"}
					value={
						!!competitionStatBlock ?
							`${competitionStatBlock?.matchWithLeastViewers?.viewerCount}, 
						${competitionStatBlock?.matchWithLeastViewers?.date.toLocaleString("sl-sl", {
								day: "2-digit",
								month: "2-digit",
								year: "numeric"
							})}` : ""}
					secondRow={!!competitionStatBlock ? `${competitionStatBlock?.matchWithLeastViewers?.homeName} - ${competitionStatBlock?.matchWithLeastViewers?.awayName} ${competitionStatBlock?.matchWithLeastViewers?.homeScore}:${competitionStatBlock?.matchWithLeastViewers?.awayScore}` : ""}/>

			</Stack>
		</Stack>
	);
}

function StatRow(props: {
	label: string,
	value: string | number | undefined,
	secondRow?: string,
}) {
	const {label, value, secondRow} = props

	return (
		<>
			<Grid container sx={{color: "#676767", minHeight: "30px", py: "4px"}} alignItems={"center"}>
				<Grid item container xs={6} sx={{pr: 1}}>
					<Typography sx={{ml: "auto"}} variant="bodySBold">{label}</Typography>
				</Grid>
				<Grid item container flexDirection={"column"} xs={6} sx={{pl: 1}}>
					<Typography variant="bodyS">{value}</Typography>
					{secondRow && <Typography variant="bodyS">{secondRow}</Typography>}
				</Grid>
			</Grid>
			<Divider/>
		</>
	);
}
