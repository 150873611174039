import {Stack} from "@mui/material";
import {ClubBanner} from "views/club/ClubBanner";
import {FootballSchoolLandingBody} from "views/footballSchool/FootballSchoolLandingBody";
import {InnerWidthContainer} from "../../components/widthControlElements";

export function FootballSchoolPrimarySchools() {
	return (
		<Stack>
			<ClubBanner
				url={"/images/football-school-banner.png"}
				mainText={"Nogometna šola NK Maribor"}
				alignText={"stretch"}/>
			<InnerWidthContainer>
				<FootballSchoolLandingBody/>
			</InnerWidthContainer>
		</Stack>
	)
}
