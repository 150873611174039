import {ClubEmblemDescriptionRow} from "./ClubEmblemDescriptionRow";
import React from "react";

export function ClubEmblemDescriptionRowShield() {
	return (
		<ClubEmblemDescriptionRow
			url={"/images/club/symbols/shield.png"}
			title={"4. Ščit"}
			text={"Tudi najizkušenejši so med turbulentnim dogajanjem prestali velik izzv. “Ne bi komentiral sojenja, zagotovo pa moram omeniti, da je težko ostati miren po takšni tekmi. NK Maribor je velik klub, igralci smo zadolženi, da igramo. Zato ne bi ocenjeval sodniških odločitev, ni pa bilo lahko ohraniti zbranosti na najvišji ravni, saj so misli, razumljivo, uhajale k drugim zadevam.”"}
		/>
	)
}
