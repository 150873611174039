import {Box, IconButton, Stack, Typography, useTheme} from "@mui/material";
import {ECompetitionType} from "@nitra/nkmb-card-components";
import React from "react";
import {useCompetitionViewContext} from "../common/CompetitionContext";
import {ChevronLeft, ChevronRight} from "@mui/icons-material";

export function ScoreAssistCompetitionSelector() {
	const {
		scoreAssistsSelectedCompetition,
		setScoreAssistsSelectedCompetition
	} = useCompetitionViewContext()
	const theme = useTheme();

	const options = [ECompetitionType.FIRST_LEAGUE, ECompetitionType.CUP, ECompetitionType.ANY_UEFA]

	const handleLeftClick = () => {
		const currentIndex = options.findIndex(option => option === scoreAssistsSelectedCompetition);
		const nextIndex = (currentIndex - 1 + options.length) % options.length;
		setScoreAssistsSelectedCompetition(options[nextIndex]);
	}

	const handleRightClick = () => {
		const currentIndex = options.findIndex(option => option === scoreAssistsSelectedCompetition);
		const nextIndex = (currentIndex + 1) % options.length;
		setScoreAssistsSelectedCompetition(options[nextIndex]);
	}


	const getLogo = (matchType: ECompetitionType) => {
		switch (matchType) {
			case ECompetitionType.FIRST_LEAGUE:
				return <img src={"/images/competition_logos/2022/SNL-pokoncni.png"} alt={"SNL"}
				            style={{height: "60px"}}/>
			case ECompetitionType.CUP:
				return <img src={"/images/competition_logos/2022/Pokal-lezeci.png"} alt={"Pokal"}
				            style={{height: "60px"}}/>
			case ECompetitionType.ANY_UEFA:
				return <Typography variant={"h6"} sx={{textAlign: "center"}}>Europske tekme</Typography>
		}
	}

	return (
		<Stack direction={"row"}
		       justifyContent={"center"}
		       alignItems={"center"}
		       sx={{height: "64px"}}
		>
			<IconButton size={"small"} sx={{p: 0, color: theme.palette.text.primary}} onClick={handleLeftClick}>
				<ChevronLeft/>
			</IconButton>
			<Box sx={{width: "130px", display: "flex", alignItems: "center", justifyContent: "center"}}>
				{getLogo(scoreAssistsSelectedCompetition)}
			</Box>
			<IconButton size={"small"} sx={{p: 0, color: theme.palette.text.primary}} onClick={handleRightClick}>
				<ChevronRight/>
			</IconButton>
		</Stack>
	);
}

