import React from "react";
import {useTheme} from "@mui/material";
import {INNER_PADDING} from "../../App";
import {useDevelopmentContext} from "@nitra/nkmb-card-components";


export const DebugGutters = () => {
	const {showGutters, absoluteGutters} = useDevelopmentContext();
	const theme = useTheme();

	if (!showGutters) return (<></>)

	const breakpointXL = theme.breakpoints.values.xl

	if (absoluteGutters) {
		const lineWidth = 16
		const opacity = 0.25
		const firstColor = `rgba(255, 0, 0, ${opacity})`
		const secondColor = `rgba(255, 255, 0, ${opacity})`
		const gradientLine = `repeating-linear-gradient(45deg, ${firstColor}, ${firstColor} 10px, ${secondColor} 10px, ${secondColor} 20px)`


		return (
			<div style={{
				position: "absolute",
				top: 0,
				height: "100vh",
				width: "100%",
				pointerEvents: "none",
			}}>
				{/* Center vertical line*/}
				<div style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					left: `calc(50% - ${lineWidth / 2}px)`,
					width: "16px",
					background: gradientLine,
				}}/>

				{/* Paper limit left */}
				<div style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					left: `calc(50% - ${lineWidth}px - ${breakpointXL / 2}px)`,
					width: lineWidth,
					background: gradientLine,
				}}/>

				{/* Paper limit right */}
				<div style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					right: `calc(50% - ${lineWidth}px - ${breakpointXL / 2}px)`,
					width: lineWidth,
					background: gradientLine,
				}}/>

				{/*	Inner width limit left*/}
				<div style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					left: `calc(50% - ${lineWidth}px - ${breakpointXL / 2}px + ${INNER_PADDING.xl * 8}px)`,
					width: lineWidth,
					background: gradientLine,
				}}/>

				{/*	Inner width limit right*/}
				<div style={{
					position: "absolute",
					top: 0,
					height: "100vh",
					right: `calc(50% - ${lineWidth}px - ${breakpointXL / 2}px + ${INNER_PADDING.xl * 8}px)`,
					width: lineWidth,
					background: gradientLine,
				}}/>
			</div>
		)

	} else {
		return (
			<div style={{
				position: "absolute",
				top: 0,
				right: "112px",
				height: "100vh",
				left: "112px",
				backgroundColor: "rgba(0,0,0,0)",
				pointerEvents: "none"
			}}>
				{Array.from(Array(13).keys()).map((value, index) =>
					<div
						key={index}
						style={{
							position: "absolute",
							height: "100vh",
							// left: `100px`,
							left: `calc(${100 / 12 * (index)}% - 12px)`,
							width: "24px",
							zIndex: 100,
							backgroundColor: "rgba(255,0,0,0.10)",
							pointerEvents: "none",
						}}
					/>
				)}
			</div>
		)
	}
}
