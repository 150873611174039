import {Stack} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {ClubBanner} from "views/club/ClubBanner";
import {ClubSymbolNavigationButtons} from "views/club/symbols/ClubSymbolNavigationButtons";
import {ClubEmblemDescriptionRowBird} from "./ClubEmblemDescriptionRowBird";
import {ClubEmblemDescriptionRowCastle} from "./ClubEmblemDescriptionRowCastle";
import {ClubEmblemDescriptionRowFlower} from "./ClubEmblemDescriptionRowFlower";
import {ClubEmblemDescriptionRowShield} from "./ClubEmblemDescriptionRowShield";
import {ClubEmblemDescriptionRowStar} from "./ClubEmblemDescriptionRowStar";

export function ClubEmblem() {
	const isMobile = useIsMobileContext()

	return (
		<Stack>
			<ClubBanner
				url={"/images/club/banners/emblem.png"}
				mainText={"Grb"}
			/>
			<ClubSymbolNavigationButtons/>
			<InnerWidthContainer>
				<Stack direction={"column"} spacing={8} sx={{pt: isMobile ? 7 : 10}}>
					<img src={"/images/club/symbols/logo-with-numbers.png"} alt={"Club emblem"}
						// style={{height: "300px", width: "249px"}}
						 style={{maxHeight: "300px", width: "auto", objectFit: "contain"}}
					/>
					<ClubEmblemDescriptionRowFlower/>
					<ClubEmblemDescriptionRowCastle/>
					<ClubEmblemDescriptionRowBird/>
					<ClubEmblemDescriptionRowShield/>
					<ClubEmblemDescriptionRowStar/>
				</Stack>
			</InnerWidthContainer>
		</Stack>
	)
}

