import {CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import {CardWithShadow, ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import {useNavigate} from "react-router-dom";
import LandingPermanentStadiumCard from "views/permanentLandingComponents/permaBottomCards/LandingPermanentStadiumCard";
import LandingPermanentTeamCard from "views/permanentLandingComponents/permaBottomCards/LandingPermanentTeamCard";
import RandomPlayerFirebaseDocument from "../../models/firestore/common/RandomPlayerFirestoreDocument";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

const LandingPermanentBottomCards = (props: {
	randomPlayer: RandomPlayerFirebaseDocument | undefined
}) => {
	const {randomPlayer} = props;

	const isMobile = useIsMobileContext()

	return isMobile
		? <LandingPermanentMobileCards player={randomPlayer}/>
		: <LandingPermanentDesktopCards player={randomPlayer}/>


}

function LandingPermanentMobileCards(props: {
	player: RandomPlayerFirebaseDocument | undefined
}) {
	const {player} = props;

	return (
		<InnerWidthContainer sx={{pt: 2}}>
			<Stack spacing={2}>
				<LandingPermanentMobileCard
					imageUrl={"/images/landing/ljudski_vrt.png"}
					navigateUrl={"/club/stadium/info"}
					label={"Ljudski vrt"}
					isLarge={true}
				/>
				<LandingPermanentTeamCard player={player}/>
				<LandingPermanentMobileCard
					imageUrl={"/images/landing/zgodovina.png"}
					navigateUrl={"/club/history"}
					label={"Zgodovina"}/>
				<LandingPermanentMobileCard
					imageUrl={"/images/landing/navijaci.png"}
					navigateUrl={"/club/fans"}
					label={"Viole Maribor"}/>
				<LandingPermanentMobileCard
					imageUrl={"/images/landing/nogometna_sola.png"}
					navigateUrl={"/footballSchool"}
					label={"Nogometna šola"}/>
				<LandingPermanentMobileCard
					imageUrl={"/images/landing/klub.png"}
					navigateUrl={"/club/symbols/emblem"}
					label={"NK Maribor"}/>
			</Stack>
		</InnerWidthContainer>
	)
}

function LandingPermanentDesktopCards(props: {
	player: RandomPlayerFirebaseDocument | undefined
}) {
	const {player} = props;

	return (
		<InnerWidthContainer
			sx={{pt: 4}}>
			<Grid
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				spacing={3}
				sx={{
					px: {xs: 2},
				}}
			>
				<Grid item xs={12} sm={6} md={8} lg={6} sx={{minWidth: "300px"}}>
					<LandingPermanentTeamCard player={player}/>
				</Grid>
				<Grid item xs={12} sm={6} md={8} lg={6} sx={{minWidth: "300px"}}>
					<LandingPermanentStadiumCard/>
				</Grid>
				<LandingPermanentDesktopCard
					imageUrl={"/images/landing/zgodovina.png"}
					navigateUrl={"/club/history"}
					label={"Zgodovina"}/>
				<LandingPermanentDesktopCard
					imageUrl={"/images/landing/navijaci.png"}
					navigateUrl={"/club/fans"}
					label={"Viole Maribor"}/>
				<LandingPermanentDesktopCard
					imageUrl={"/images/landing/nogometna_sola.png"}
					navigateUrl={"/footballSchool"}
					label={"Nogometna šola"}/>
				<LandingPermanentDesktopCard
					imageUrl={"/images/landing/klub.png"}
					navigateUrl={"/club/symbols/emblem"}
					label={"NK Maribor"}
					isLast={true}
				/>
			</Grid>
		</InnerWidthContainer>
	)
}

function LandingPermanentDesktopCard(props: {
	imageUrl: string,
	navigateUrl: string,
	label: string,
	isLast?: boolean,
}) {
	const navigate = useNavigate()

	const {imageUrl, navigateUrl, label, isLast} = props;

	return (
		<Grid item xs={12} sm={6} md={3} sx={{minWidth: "300px", mr: isLast ? "auto" : null}}>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column"}}
				style={{position: "relative"}}
				onClickSpecialAction={() => navigate(navigateUrl)}
				fullSizeImageUrl={imageUrl}
			>
				<CardContent sx={{mt: "auto", mx: 3, mb: 3, zIndex: 1, pl: 0}}>
					<Typography
						variant={"podnaslovM"}
						sx={{color: "background.paper", pl: 0, ml: "-24px", mr: "auto"}}
					>
						{label}
					</Typography>
				</CardContent>
				<ShareArea shareUrl={"/history"} brightText={true}
				           sx={{position: "absolute", zIndex: 1, right: 24, bottom: 32}}/>
			</CardWithShadow>
		</Grid>
	)
}

function LandingPermanentMobileCard(props: {
	imageUrl: string,
	navigateUrl: string,
	label: string,
	isLarge?: boolean,
}) {
	const {imageUrl, navigateUrl, label, isLarge} = props;

	const navigate = useNavigate()
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Grid item xs={12} sm={6} md={3} sx={{minWidth: "300px"}}>
			<CardWithShadow
				sx={{display: "flex", flexDirection: "column"}}
				style={{position: "relative"}}
				onClickSpecialAction={() => navigate(navigateUrl)}
				fullSizeImageUrl={imageUrl}
				cardHeightOverride={isLarge ? 400 : 141}
			>
				<CardContent sx={{mt: "auto", mx: 2.5, mb: 2.5, zIndex: 1}}>
					<Typography
						variant={"h4"}
						sx={{color: "background.paper",
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
					>
						{label}
					</Typography>
				</CardContent>
				<ShareArea shareUrl={"/history"} brightText={true}
				           sx={{position: "absolute", zIndex: 1, right: 24, bottom: 24}}/>
			</CardWithShadow>
		</Grid>
	)
}

export default LandingPermanentBottomCards
