import {SupportStaffListFirestoreDocument} from "../../models";
import {Box, Grid, Stack, Typography, useTheme} from "@mui/material";
import {SupportStaffMobileCard} from "@nitra/nkmb-card-components";
import React from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function SupportStaffMobileGroup(props: {
	group: {
		label: string,
		staff: SupportStaffListFirestoreDocument[]
	}
}) {
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Stack direction={"column"} sx={{width: "100%", pt: 4,}}>
			<Typography variant={"h5"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{props.group.label}</Typography>
			<Grid container spacing={2} justifyContent={"space-between"}>
				{props.group.staff.map((person, index) => (
					<Grid key={index} item
					      xs={6}
					      lg={4}
					      xl={3}
					      sx={{marginRight: index === props.group.staff.length - 1 ? "auto" : "unset",}}
					>
						<Box style={{
							maxWidth: "178px",
							width: "100%",
							paddingTop: `min(100%, 178px)`,
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								<SupportStaffMobileCard staff={person}/>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Stack>
	)
}