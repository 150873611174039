import {Box, Grid, SxProps} from "@mui/material";
import {useBreakpointContext, useIsMobileContext} from "@nitra/nkmb-card-components";
import React, {PropsWithChildren} from "react";
import {CardContainerTitle} from "./CardContainerTitle";

/**
 * Container for cards that follow the 12, 6, 4, 3 grid pattern and have a title for the group
 * @param props
 * @constructor
 */
export function CardContainerWithName(props: PropsWithChildren<{
	title: string,
	titleClickAction?: () => void,
	cardAlignLeft?: boolean,
	cardAlignLastRowLeft?: boolean,
	sx?: SxProps,
	innerSx?: SxProps,
	spacingOverride?: number,
}>) {
	const {title, children, titleClickAction, cardAlignLeft, spacingOverride, cardAlignLastRowLeft} = props

	const isMobile = useIsMobileContext();
	const breakpoint = useBreakpointContext();

	return (
		<Box sx={{
			pb: 5,
			...props.sx
		}}>
			<CardContainerTitle
				title={title}
				titleAction={titleClickAction}
				sx={{
					ml: breakpoint === "md" ? 1.5 : "unset",
					pb: isMobile ? 2 : 3,
				}}
			/>
			<Grid
				container
				spacing={spacingOverride ?? 3}
				sx={{
					justifyContent: isMobile ? null : cardAlignLeft ? "start" : "center",
					'& > :last-child': {
						mr: cardAlignLastRowLeft ? "auto" : "unset",
					},
					...props.innerSx,
				}}
			>
				{React.Children.map(children, (child, index) =>
					<Grid
						key={index}
						item
						xs={isMobile ? 12 : false}
					>
						{child}
					</Grid>
				)}
			</Grid>
		</Box>
	)
}

