import {Container, Grid, Stack, Typography, useTheme} from "@mui/material";
import {INNER_MAX_WIDTH} from "App";
import React from "react";
import {BasicVipElement} from "./BasicVipElement";
import {VipTexts} from "./VipTexts";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

function VipBasic() {
	const {isIos} = useIosContext()
	const theme = useTheme();
	const isMobile = useIsMobileContext()

	return (
		<>
			<Container sx={{
				backgroundImage: "url('/images/hospitality/hospitalityBackground.png')",
				backgroundPosition: 'center',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				minHeight: '100vh', // Set the minimum height to cover the full viewport
				width: '100%', // Set the width to cover the full viewport
				pt: "650px",
				pb: "150px"
			}}>
				<Stack alignItems={"center"}>
					<Typography variant={isMobile ? "h3" : "h1"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}} color={theme => theme.palette.background.default}>NK Maribor
						5*</Typography>
				</Stack>
				<Grid container direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={4}
				      sx={{width: "100%", maxWidth: INNER_MAX_WIDTH, mx: "auto", my: 4}}>
					<BasicVipElement {...VipTexts.basic.vps}/>
					<BasicVipElement {...VipTexts.basic.skybox}/>
				</Grid>
			</Container>
		</>
	)
}

export default VipBasic
