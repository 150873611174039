import {PlayerStatTab} from "../../team/playerDetailsPage/PlayerStatTab";
import {InnerWidthContainer} from "../../../components/widthControlElements";
import {PlayerStatsContextProvider} from "../../team/playerDetailsPage/PlayerStatsContext";
import {
	CompetitionFilterButtonsContextProvider
} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";

export function ClubStatisticsPlayerPage() {
	return (
		<CompetitionFilterButtonsContextProvider allowMultipleSelection={false}>
			<PlayerStatsContextProvider>
				<InnerWidthContainer>
					<PlayerStatTab/>
				</InnerWidthContainer>
			</PlayerStatsContextProvider>
		</CompetitionFilterButtonsContextProvider>
	)
}