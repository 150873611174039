import {FormControl, InputAdornment, TextField, useTheme} from "@mui/material";
import React from "react";
import {NkmbIconSearch} from "@nitra/nkmb-theme";

export function NkmbSearchBar(props: {
	search: string,
	setSearch: (value: string) => void,
	callSearch: () => void,
}) {
	const {search, setSearch, callSearch} = props
	const theme = useTheme()

	return (
		<FormControl sx={{m: 1}} variant={"standard"}>
			<TextField
				placeholder="iskanje"
				value={search}
				onChange={(e) => setSearch(e.target.value)}
				onKeyUp={(event) => {
					if (event.key === 'Enter' || event.key === "Done" || event.key === "Go") {
						event.preventDefault();
						callSearch();
					}
				}}
				InputProps={{
					startAdornment: <InputAdornment position="start"><NkmbIconSearch/></InputAdornment>,
					style: {
						borderRadius: '50px',
						backgroundColor: theme.palette.background.default,
						padding: 0,
						paddingInline: "8px",
						height: "25px",
					}
				}}
				sx={{
					borderRadius: '50px',
				}}
			/>

		</FormControl>
	)
}