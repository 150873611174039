import {Box, Typography, useTheme} from "@mui/material";
import {ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {PaperWidthContainer} from "../../components/widthControlElements";
import {useLocation} from "react-router-dom";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function ClubBanner(props: {
	url: string,
	mainText: string,
	subText?: string,
	extraComponent?: React.ReactNode,
	alignText?: "center" | "stretch"
}) {
	const {url, mainText, subText, extraComponent, alignText} = props
	const location = useLocation()

	const theme = useTheme();
	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()

	return (
		<PaperWidthContainer sx={{mb: 4}}>
			<Box sx={{
				position: "relative",
				backgroundImage: `url(${url}), url(/images/placeholder600x360.png)`,
				height: {
					xs: "unset",
					sm: "500px",
					md: "600px",
					lg: "680px",
				},
				pt: {
					xs: "45%",
					sm: "unset"
				},
				width: "100%",
				backgroundSize: "cover",
				backgroundPosition: "center",
				flexGrow: 1,
				display: "flex",
				alignItems: alignText ?? isMobile ? "stretch" : "center",
				justifyContent: "flex-end",
				flexDirection: "column",
			}}>
				<Box>
					<div style={{
						backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0.8))",
						// zIndex: 0,
						position: "absolute", top: 0, left: 0, right: 0, bottom: 0
					}}/>
				</Box>
				<Box sx={{
					position: "relative",
					display: "flex",
					alignItems: isMobile ? "start" : "center",
					justifyContent: "flex-end",
					flexDirection: "column",
					px: 2
				}}>
					<Typography sx={{
						color: theme.palette.background.paper,
						pb: 2,
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}} variant={isMobile ? "h4" : "h2"}>
						{mainText}
					</Typography>
					{!isMobile && subText && <Typography sx={{
						color: theme.palette.background.paper,
						pb: 1,
					}} variant={"bodyL"}>
						{subText}
                    </Typography>}
					<ShareArea shareUrl={location.pathname} brightText={true} sx={{pb: 2, ml: 0,}}/>
					{extraComponent}
				</Box>
			</Box>
		</PaperWidthContainer>
	)
}
