import {PropsWithChildren} from "react";
import {Box, Typography} from "@mui/material";
import {useDevelopmentContext} from "@nitra/nkmb-card-components";

export function Todo(props: PropsWithChildren<{
	label: string
}>) {
	const {label} = props;

	const {showTodos} = useDevelopmentContext();

	if (!showTodos) {
		return null;
	}

	return (
			<Box sx={{backgroundColor: "red", p: 0, m: 0}}>
				<Typography variant={"h6"} color={"warning"}>
					{label}
				</Typography>
			</Box>
	)
}
