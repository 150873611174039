import {Box, Grid, Stack} from "@mui/material";
import {
	ECompetitionType,
	isEuroCompetitionType,
	MatchListElementFirestoreDocument,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import React, {useEffect, useState} from "react";
import MatchContainerWithTitle from "../../../components/Competitions/MatchContainerWithTitle";
import {useCompetitionViewContext} from "../common/CompetitionContext";
import JumpToNextMatchButton from "./JumpToNextMatchButton";
import {CompetitionsRightStack} from "../common/CompetitionsRightStack";
import {CompetitionFilterButtons} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtons";

export function CompetitionsMatchList() {
	const {matches} = useCompetitionViewContext();

	const [filteredMatches, setFilteredMatches] = useState<MatchListElementFirestoreDocument[]>([]);

	const [monthWithMatches, setMonthWithMatches] = useState<{
		[key: string]: MatchListElementFirestoreDocument[]
	}>({});

	const {
		competitionsFilter,
		setFirstMatchKey,
		setCurrentMatchKey,
		scrollContainerRef
	} = useCompetitionViewContext();
	const isMobile = useIsMobileContext();

	useEffect(() => {
		if (competitionsFilter && competitionsFilter.length > 0) {
			setFilteredMatches(
				matches.filter((match) => competitionsFilter.includes(match.competition) ||
					(competitionsFilter.indexOf(ECompetitionType.ANY_UEFA) !== -1 && isEuroCompetitionType(match.competition))
				)
			)
		} else {
			setFilteredMatches(matches);
		}
	}, [competitionsFilter, matches])

	useEffect(() => {
		setFirstMatchKey(findFirstItemKey());
		setCurrentMatchKey(findCurrentItemKey());
		groupMatchesByMonth();
	}, [filteredMatches])


	function findFirstItemKey(): string {
		if (filteredMatches.length === 0) return "";

		const firstItem = filteredMatches.reduce((earliest, item) => {
			return item.date < earliest.date ? item : earliest;
		});

		return firstItem ? firstItem.key : "";
	}

	function findCurrentItemKey(): string {
		const sortedItems = [...filteredMatches].sort((a, b) => a.date.valueOf() - b.date.valueOf());
		const lastDoneItem = sortedItems.reverse().find(item => item.isDone);

		return lastDoneItem ? lastDoneItem.key : "";
	}


	function groupMatchesByMonth() {
		const monthWithMatches: {
			[key: string]: MatchListElementFirestoreDocument[]
		} = {};

		filteredMatches.forEach((match) => {
			const month = new Date(match.date).getMonth();
			if (monthWithMatches[month] === undefined) {
				monthWithMatches[month] = [];
			}
			monthWithMatches[month].push(match);
		})

		// sort matches by date in each month
		Object.keys(monthWithMatches).forEach((month) =>
			monthWithMatches[month].sort((a, b) =>
				new Date(a.date).getTime() - new Date(b.date).getTime()
			)
		)

		setMonthWithMatches(monthWithMatches);
	}

	return (
		<Grid container sx={{pt: 4, maxWidth: "100vw"}}>
			<Box sx={{flexGrow: 1, minWidth: isMobile ? "unset" : "752px"}}>
				<Box sx={{position: "relative"}}>
					<Stack direction={"row"} spacing={1}
					       sx={{
						       position: "absolute",
						       top: 0,
						       zIndex: 10,
						       right: 0,
						       backgroundColor: "background.paper",
						       mr: 1,
						       pr: 1,
					       }}>
						<JumpToNextMatchButton/>
						{!isMobile && <CompetitionFilterButtons/>}
					</Stack>
					<Stack spacing={0}
					       ref={scrollContainerRef}
					       sx={{
						       pr: 2,
						       overflowY: "auto",
						       '&::-webkit-scrollbar': {
							       width: 0,
							       background: 'transparent',
						       },
						       scrollbarWidth: 'none', // For Firefox
						       // height: `calc(100vh - ${barHeights.filterBar.stop}px)`,
						       height: "1000px",
						       '& > *:not(:second-child)': {
							       mt: 5,
						       },
					       }}>
						{Object.keys(monthWithMatches)
							.sort((a, b) => {
								let firstValue = parseInt(a);
								let secondValue = parseInt(b);

								if (firstValue < 5) firstValue += 100
								if (secondValue < 5) secondValue += 100

								return firstValue - secondValue;
							})
							.map((month, index) =>
								<MatchContainerWithTitle key={index} matches={monthWithMatches[month]}/>
							)}
					</Stack>
				</Box>
			</Box>
			{!isMobile && <CompetitionsRightStack/>}
		</Grid>
	)
}
