import {NationFlagRound} from "@nitra/nkmb-theme";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBannerNationalFlagMobile() {
	const player = usePlayerDetailsContext()

	return (
		<NationFlagRound
			countryCode={player!.nationality}
			sx={{
				position: "absolute",
				bottom: "0px",
				left: "0px",
			}}
			svgProps={{
				height: "100px",
				width: "100px",
			}}
		/>
	)
}
