import {Box} from "@mui/material";
import React from "react";
import {CompetitionStatisticsClubPlayersTable} from "./CompetitionStatisticsClubPlayersTable";

export function MobileCompetitionStatistics() {
	return (
		<Box>
			<CompetitionStatisticsClubPlayersTable/>
		</Box>
	);
}