import {Stack} from "@mui/material";
import {
	ButtonTabNavigatorWithRouter,
	DecadeCard,
	DecadeListFirestoreDocument,
	LegendCard,
	LegendListFirestoreDocument,
	WarriorCard,
	WarriorListFirestoreDocument
} from "@nitra/nkmb-card-components";
import {CardContainerWithName} from "components/CardContainerWithName";
import {CardContainerWithoutName} from "components/CardContainerWithoutName";
import {InnerWidthContainer} from "components/widthControlElements";
import {getClubLegendWarriorDecade} from "firestore/BaseFirestore";
import React, {useEffect} from "react";
import {ClubBanner} from "../ClubBanner";

export function ClubHistory() {
	const [legends, setLegends] = React.useState<LegendListFirestoreDocument[]>([]);
	const [warriors, setWarriors] = React.useState<WarriorListFirestoreDocument[]>([]);
	const [decades, setDecades] = React.useState<DecadeListFirestoreDocument[]>([]);

	const fetchData = () => {
		getClubLegendWarriorDecade().then((payload) => {
			setLegends(payload.legends);
			setWarriors(payload.warriors);
			setDecades(payload.decades);
		})
	}

	useEffect(() => {
		fetchData()
	}, [])

	const tabs = [{
		label: "Legende",
		path: "legends",
		component: <>
			<CardContainerWithName title={"Legende - upokojeni dresi"} cardAlignLeft={true}>
				{legends.filter(legend => legend.jerseyRetiredPermanent).map((legend, index) =>
					<LegendCard key={index} data={legend}/>
				)}
			</CardContainerWithName>
			<CardContainerWithName title={"Legende - upokojeni dresi za 10 let"} cardAlignLeft={true}>
				{legends.filter(legend => legend.jerseyRetiredTemporary).map((legend, index) =>
					<LegendCard key={index} data={legend}/>
				)}
			</CardContainerWithName>
			<CardContainerWithName title={"Igralci z največ nastopi v posameznih desetletjih"} cardAlignLeft={true}>
				{legends.filter(legend => !legend.jerseyRetiredPermanent && !legend.jerseyRetiredTemporary && !legend.isCoach).map((legend, index) =>
					<LegendCard key={index} data={legend}/>
				)}
			</CardContainerWithName>
			<CardContainerWithName title={"Legende - trenerji"} cardAlignLeft={true}>
				{legends.filter(legend => legend.isCoach).map((legend, index) =>
					<LegendCard key={index} data={legend}/>
				)}
			</CardContainerWithName>
		</>
	}, {
		label: "Vijolčni bojevnik",
		path: "warriors",
		component: <CardContainerWithoutName sx={{pt: 5}}>
			{warriors
				.sort((a, b) => b.year - a.year)
				.map((warrior, index) =>
				<WarriorCard key={index} data={warrior}/>
			)}
		</CardContainerWithoutName>
	}, {
		label: "Po desetletjih",
		path: "decades",
		component:
			<CardContainerWithoutName sx={{pt: 5}}>
				{decades.map((decade, index) =>
					<DecadeCard key={index} data={decade}/>
				)}
			</CardContainerWithoutName>
	}]

	return (
		<Stack sx={{m: 0, p: 0}}>
			<ClubBanner
				url={"/images/club/banners/history.png"}
				mainText={"maribor vijolčni"}
			/>
			<InnerWidthContainer>
				<ButtonTabNavigatorWithRouter tabs={tabs}/>
			</InnerWidthContainer>
		</Stack>
	)
}

