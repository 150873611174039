import {Stack} from "@mui/material";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getWarrior} from "firestore/BaseFirestore";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Banner from "../../../components/Banner";

export function WarriorProfile() {
	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [bannerName, setBannerName] = useState("");
	const [bannerUrl, setBannerUrl] = useState("");
	const [year, setYear] = useState(0);

	const {key} = useParams();

	const getData = (_key: string) => {
		getWarrior(_key).then((data) => {
			setContent(data.content);
			setBannerName(data.firstName + " " + data.lastName);
			setBannerUrl(data.image1440x680url);
			setYear(data.year);
		})
	}

	useEffect(() => {
		if (!key) {
			console.error("No key provided");
			return;
		}

		getData(key);
	}, [key])

	return (
		<Stack>
			<Banner title={bannerName} imageUrl={bannerUrl}/>
			<ContentContainer content={content}/>
		</Stack>
	)
}
