import {useTheme} from "@mui/material";
import {
	ECompetitionType,
	MatchListElementFirestoreDocument,
	PlayerScoreStatFirestoreDocument,
	useCurrentSeasonContext,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {getSeasonMatches} from "../../../firestore/BaseFirestore";

interface CompetitionViewBars {
	appBar: {
		start: number,
		stop: number
	},
	seasonPicker: {
		start: number,
		stop: number
	},
	tabBar: {
		start: number,
		stop: number
	},
	filterBar: {
		start: number,
		stop: number
	}
}

const CompetitionViewContext = createContext<{
	season: string,
	setSeason: (season: string) => void,

	competitionsFilter: ECompetitionType[],
	setCompetitionsFilter: (newValue: ECompetitionType[]) => void,

	barHeights: CompetitionViewBars,
	setBarHeights: (newValue: CompetitionViewBars) => void,

	currentRef: React.MutableRefObject<HTMLDivElement | null>,
	firstRef: React.MutableRefObject<HTMLDivElement | null>,

	firstMatchKey: string,
	setFirstMatchKey: (newValue: string) => void,
	currentMatchKey: string,
	setCurrentMatchKey: (newValue: string) => void,

	scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>,

	scoreAssistsSelectedCompetition: ECompetitionType,
	setScoreAssistsSelectedCompetition: (newValue: ECompetitionType) => void,
	scoreStats: PlayerScoreStatFirestoreDocument[],
	setScoreStats: (newValue: PlayerScoreStatFirestoreDocument[]) => void,
	assistStats: PlayerScoreStatFirestoreDocument[],
	setAssistStats: (newValue: PlayerScoreStatFirestoreDocument[]) => void,
	filteredScoreStats: PlayerScoreStatFirestoreDocument[],
	filteredAssistStats: PlayerScoreStatFirestoreDocument[],

	matches: MatchListElementFirestoreDocument[],
} | null>(null)

export function useCompetitionViewContext() {
	const context = useContext(CompetitionViewContext);
	if (context === null) {
		throw new Error('useCompetitionViewContext must be used within a CompetitionViewContext');
	}
	return context;
}

export function CompetitionContext(props: PropsWithChildren) {

	const [competitionsFilter, setCompetitionsFilter] = useState<ECompetitionType[]>([]);
	const theme = useTheme();
	const isMobile = useIsMobileContext();
	const {currentSeasonString} = useCurrentSeasonContext()
	const [season, setSeason] = useState(currentSeasonString ?? "42");


	const appBarHeight = isMobile ? parseInt(theme.spacing(8).replace("px", "")) : 76;
	const seasonPickerHeight = 42;
	const tabBarHeight = 36;
	const filterBarHeight = 44;
	const [barHeights, setBarHeights] = useState<CompetitionViewBars>({
		appBar: {
			start: 0,
			stop: appBarHeight
		},
		seasonPicker: {
			start: appBarHeight,
			stop: appBarHeight + seasonPickerHeight,
		},
		tabBar: {
			start: appBarHeight + seasonPickerHeight,
			stop: appBarHeight + seasonPickerHeight + tabBarHeight
		},
		filterBar: {
			start: appBarHeight + seasonPickerHeight + tabBarHeight,
			stop: appBarHeight + seasonPickerHeight + tabBarHeight + filterBarHeight
		}
	});
	const currentRef = React.useRef<HTMLDivElement | null>(null);
	const firstRef = React.useRef<HTMLDivElement | null>(null);
	const [firstMatchKey, setFirstMatchKey] = useState("");
	const [currentMatchKey, setCurrentMatchKey] = useState("");
	const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
	const [scoreStats, setScoreStats] = useState<PlayerScoreStatFirestoreDocument[]>([]);
	const [assistStats, setAssistStats] = useState<PlayerScoreStatFirestoreDocument[]>([]);
	const [filteredScoreStats, setFilteredScoreStats] = useState<PlayerScoreStatFirestoreDocument[]>([]);
	const [filteredAssistStats, setFilteredAssistStats] = useState<PlayerScoreStatFirestoreDocument[]>([]);
	const [scoreAssistsSelectedCompetition, setScoreAssistsSelectedCompetition] = useState<ECompetitionType>(ECompetitionType.FIRST_LEAGUE);
	const [matches, setMatches] = useState<MatchListElementFirestoreDocument[]>([]);


	useEffect(() => {
		setMatches([])
		setScoreStats([])
		setAssistStats([])
		if (season && season != "42") {
			getSeasonMatches(season).then((data) => {
				setMatches(data.matches)
				setScoreStats(data.scoreStats)
				setAssistStats(data.scoreStats)
			})
		}
	}, [season])

	useEffect(() => {
		const newScores = scoreStats.filter(stat => scoreAssistsSelectedCompetition === stat.matchType);
		const newAssists = assistStats.filter(stat => scoreAssistsSelectedCompetition === stat.matchType);

		newScores.sort((a, b) => b.goals - a.goals);
		newAssists.sort((a, b) => b.assists - a.assists);

		setFilteredScoreStats(newScores);
		setFilteredAssistStats(newAssists);
	}, [scoreAssistsSelectedCompetition, scoreStats, assistStats]);

	useEffect(() => {
		setSeason(currentSeasonString)
	}, [currentSeasonString]);

	return (<CompetitionViewContext.Provider value={{
		season,
		setSeason,
		competitionsFilter,
		setCompetitionsFilter,
		barHeights,
		setBarHeights,
		currentRef,
		firstRef,
		firstMatchKey,
		setFirstMatchKey,
		currentMatchKey,
		setCurrentMatchKey,
		scrollContainerRef,
		scoreStats,
		setScoreStats,
		assistStats,
		setAssistStats,
		scoreAssistsSelectedCompetition,
		setScoreAssistsSelectedCompetition,
		filteredScoreStats,
		filteredAssistStats,
		matches
	}}>
		{props.children}
	</CompetitionViewContext.Provider>)
}
