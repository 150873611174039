import {Box, Grid, Stack, Typography} from "@mui/material";
import {Group} from "models/Group";
import {Person} from "models/Person";
import React from "react";
import {PersonsGridPersonElement} from "./PersonsGridPersonElement";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

export function PersonsGridGroupElement(props: {
	group: Group<Person>
}) {
	const {group} = props;

	const isMobile = useIsMobileContext()

	return (
		<Stack direction={"column"} sx={{width: "100%", pt: 7,}}>
			<Typography variant={isMobile ? "podnaslovXS" : "podnaslovM"}>{group.name}</Typography>
			<Grid container spacing={2} justifyContent={"space-between"} sx={isMobile ? {pt: 2} : {}}>
				{group.items.map((person, index) => (
					<Grid key={index} item
					      xs={6}
					      sm={6}
					      md={4}
					      lg={3}
					      xl={3}
					      sx={{marginRight: index === group.items.length - 1 ? "auto" : "unset",}}
					>
						<Box style={{
							maxWidth: "331px",
							width: "100%",
							paddingTop: `min(100%, 331px)`,
							position: 'relative',
						}}>
							<Box
								style={{
									position: 'absolute',
									top: 0,
									left: 0,
									bottom: 0,
									right: 0,
									display: 'flex',
									justifyContent: 'center',
								}}>
								<PersonsGridPersonElement person={person}/>
							</Box>
						</Box>
					</Grid>
				))}
			</Grid>
		</Stack>
	)
}
