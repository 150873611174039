import {
	StadiumLegendaryMatchCardDesktop,
	StadiumLegendaryMatchCardMobile,
	StadiumLegendaryMatchListFirestoreDocument,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import React from "react";

export function StadiumLegendaryMatchCard(props: {
	data: StadiumLegendaryMatchListFirestoreDocument
}) {
	const isMobile = useIsMobileContext()

	return isMobile
		? <StadiumLegendaryMatchCardMobile data={props.data}/>
		: <StadiumLegendaryMatchCardDesktop data={props.data}/>
}
