import {Stack} from "@mui/material";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getClubInfoPage} from "firestore/BaseFirestore";
import {ContentElementPageCacheFirebaseDocumentUnion, EClubInfoPage} from "models";
import {useEffect, useState} from "react";
import {ClubSymbolNavigationButtons} from "views/club/symbols/ClubSymbolNavigationButtons";
import {ClubBanner} from "../ClubBanner";
import {ClubStadiumTabNavigatorButtons} from "../stadium/ClubStadiumTabNavigatorButtons";

/**
 * This component is used to render a page of the club info section. Uses EClubInfoPage as the identifier
 * @param props
 * @constructor
 */
export function ContentBasedClubPage(props: {
	page: EClubInfoPage,
}) {
	const {page} = props;

	const [bannerHeaderMainText, setBannerHeaderMainText] = useState("");
	const [bannerHeaderSubText, setBannerHeaderSubText] = useState<string | undefined>();
	const [bannerHeaderImageUrl, setBannerHeaderImageUrl] = useState("");
	const [content, setContent] = useState<ContentElementPageCacheFirebaseDocumentUnion[]>([]);

	const load = () => {
		getClubInfoPage(page).then(payload => {
			setBannerHeaderMainText(payload.bannerHeaderMainText);
			setBannerHeaderSubText(payload.bannerHeaderSubText);
			setBannerHeaderImageUrl(payload.bannerHeaderImageUrl);
			setContent(payload.content);
		})
	}

	useEffect(() => {
		load();
	}, [page]);

	return (
		<Stack>
			<ClubBanner
				url={bannerHeaderImageUrl}
				mainText={bannerHeaderMainText}
				subText={bannerHeaderSubText}
			/>
			{page === EClubInfoPage.STADIUM_HISTORY && <ClubStadiumTabNavigatorButtons/>}
			{(page === EClubInfoPage.COLORS_AND_SLOGANS || page === EClubInfoPage.REGION) &&
                <ClubSymbolNavigationButtons/>}
			<ContentContainer content={content}/>
		</Stack>
	)
}


