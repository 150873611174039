import {
	CompetitionHistoryFirestoreDocument
} from "../../../models/firestore/pageCache/CompetitionHistoryFirestoreDocument";
import {Grid, Stack} from "@mui/material";
import {CardContainerTitle} from "../../../components/CardContainerTitle";
import {SeasonResultContainer} from "./SeasonResultContainer";
import React from "react";

export function CompetitionHistoryContainer(props: { competitionHistory: CompetitionHistoryFirestoreDocument }) {
	const {label, seasonResults} = props.competitionHistory
	return (
		<Stack>
			<CardContainerTitle title={label}/>
			<Grid container spacing={2}>
				{seasonResults.map((seasonResult, index) =>
					<Grid item key={index} xs={12} sm={6} md={4} lg={3}>
						<SeasonResultContainer seasonResult={seasonResult}/>
					</Grid>
				)}
			</Grid>
		</Stack>
	)
}
