import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IAdvancedNewsParameters} from "../../../firestore/BaseFirestore";
import PageDto from "../../../models/common/PagableDto";
import {IGetVideosParams} from "../../../firestore/specificGetters/VideoRequests";

interface NewsSearchContextType<T> {
	elements: T[],

	month: number,
	setMonth: (month: number) => void,
	year: number
	setYear: (year: number) => void,
	search: string,
	setSearch: (search: string) => void,

	callGetFunction: (isCalledBySearchChange?: boolean) => void,

	isLoading: boolean,
	page: number,
	totalPages: number,
}

const NewsSearchContext = createContext<NewsSearchContextType<any> | null>(null)

export default function useNewsSearchContext<T>() {
	const context = useContext(NewsSearchContext)
	if (!context) {
		throw new Error("useNewsSearchContext must be used within a NewsSearchContextProvider")
	}
	return context as NewsSearchContextType<T>
}

export function NewsSearchContextProvider<T>(props: PropsWithChildren<{
	getFunction: (params: IGetVideosParams | IAdvancedNewsParameters) => Promise<PageDto<T>>,
	sortFunction: (a: T, b: T) => number,
	idAccessor: (element: T) => string
}>) {
	const location = useLocation();
	const navigate = useNavigate()

	const searchParams = new URLSearchParams(location.search);
	const {getFunction, sortFunction, idAccessor} = props;

	const [year, setYear] = useState<number>(parseInt(searchParams.get("year") ?? "-1"))
	const [month, setMonth] = useState<number>(parseInt(searchParams.get("month") ?? "-1"))
	const [search, setSearch] = useState<string>(searchParams.get("search") ?? "")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [page, setPage] = useState<number>(parseInt(searchParams.get("page") ?? "0"))
	const [totalPages, setTotalPages] = useState<number>(-1)
	const [totalResults, setTotalResults] = useState<number>(-1)
	const [elements, setElements] = useState<T[]>([])
	const [lastId, setLastId] = useState<string | undefined>(undefined);


	useEffect(() => {
		const searchParams = new URLSearchParams();
		searchParams.set('page', page.toString());
		if (search.length > 0) {
			searchParams.set('search', search)
		} else {
			searchParams.delete('search')
		}
		if (year != -1) {
			searchParams.set('year', year.toString())
		} else {
			searchParams.delete('year')
		}
		if (month != -1) {
			searchParams.set('month', month.toString())
		} else {
			searchParams.delete('month')
		}


		navigate({
			pathname: location.pathname,
			search: '?' + searchParams.toString()
		}, {replace: true});
	}, [page, search, month, year, navigate, location.pathname]);

	function callGetFunction(
		isCalledBySearchChange: boolean = false
	) {
		if (isLoading) return
		if (!isCalledBySearchChange && (elements.length === totalResults)) return
		if (!isCalledBySearchChange && (page >= totalPages && totalPages !== -1)) return
		setIsLoading(true)
		getFunction({
			year: year === -1 ? undefined : year,
			month: month === -1 ? undefined : month,
			searchQuery: search.length > 2 ? search : undefined,
			lastId: isCalledBySearchChange ? undefined : lastId,
			page: page
		})
			.then(
				(newElements) => {
					if (newElements.content.length === 0) {
						setIsLoading(false)
						return
					}

					const sorted = (isCalledBySearchChange ?
						[...newElements.content] :
						[...newElements.content, ...elements])
						.sort(sortFunction)

					const unique = sorted.filter((v, i, a) => a.findIndex(t => (idAccessor(t) === idAccessor(v))) === i)

					setLastId(idAccessor(unique[unique.length - 1]))
					setElements(unique)
					setIsLoading(false)

					setPage(newElements.number)
					setTotalPages(newElements.totalPages)
					setTotalResults(newElements.totalElements)
				},
				(error) => {
					console.error(error)
					setIsLoading(false)
				},
			)
	}

	useEffect(() => {
		setElements([])
		callGetFunction(true)
	}, [year, month]);

	return (
		<NewsSearchContext.Provider value={{
			elements,

			month, setMonth,
			year, setYear,
			search, setSearch,

			callGetFunction,
			isLoading,
			page,
			totalPages,
		}}>
			{props.children}
		</NewsSearchContext.Provider>
	)
}