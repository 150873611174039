import {CompetitionStatisticsPlayerFirestoreDocument, EPlayerPositionToPretty} from "../../../models";
import React, {useEffect} from "react";
import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import {alpha, Box, Stack, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import {
	CompetitionStatisticsClubPlayersSingleCompetitionRow
} from "./CompetitionStatisticsClubPlayersSingleCompetitionRow";

function calculateAge(dateOfBirth: Date): number {
	const diff = Date.now() - dateOfBirth.getTime();
	const ageDate = new Date(diff);
	return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function CompetitionStatisticsClubPlayersCombinedRow(props: {
	player: CompetitionStatisticsPlayerFirestoreDocument
}) {
	const {
		firstName, lastName, jerseyNumber, position, competitionStats, dateOfBirth
	} = props.player

	const [open, setOpen] = React.useState(false);


	const [goals, setGoals] = React.useState<number>(0);
	const [assists, setAssists] = React.useState<number>(0);
	const [yellowCardCount, setYellowCardCount] = React.useState<number>(0);
	const [lastYellowCardRound, setLastYellowCardRound] = React.useState<number | undefined>();
	const [redCardCount, setRedCardCount] = React.useState<number>(0);
	const [minutes, setMinutes] = React.useState<number>(0);
	const [appearances, setAppearances] = React.useState<number>(0);

	const isMobile = useIsMobileContext();
	const theme = useTheme();

	useEffect(() => {
		setGoals(competitionStats.reduce((acc, curr) => acc + curr.goals, 0));
		setAssists(competitionStats.reduce((acc, curr) => acc + curr.assists, 0));
		setYellowCardCount(competitionStats.reduce((acc, curr) => acc + curr.yellowCards, 0));
		setLastYellowCardRound(competitionStats.find((stat) => stat.competitionType === ECompetitionType.FIRST_LEAGUE)?.roundOfLastYellowCard);
		setRedCardCount(competitionStats.reduce((acc, curr) => acc + curr.redCards, 0));
		setMinutes(competitionStats.reduce((acc, curr) => acc + curr.minutes, 0));
		setAppearances(competitionStats.reduce((acc, curr) => acc + curr.matches, 0));
	}, [competitionStats]);

	return (
		<>
			<TableRow
				onClick={() => setOpen(!open)}
				sx={{
					px: 1,
					color: isMobile ? "#676767" : theme.palette.text.primary,
					backgroundColor: open ? alpha(theme.palette.text.primary, 0.03) : theme.palette.background.default,
				}}
			>
				<TableCell align={"center"}><Typography
					sx={{color: "#676767"}}
					variant={isMobile ? "bodyM" : "podnaslovM"}>{jerseyNumber}</Typography></TableCell>
				<TableCell sx={{display: "flex", alignItems: "center", minHeight: "40.5px"}}>
					{!isMobile &&
						(props.player.face42x42url ?
							<img src={props.player.face42x42url}
							     style={{"borderRadius": "40px", minWidth: "40px", height: "40px", width: "40px"}}/>
							:
							<Box sx={{
								width: "40px",
								height: "40px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}>
								<img src={props.player.face42x42url ?? "/images/mask_group.png"}
								     style={{
									     "borderRadius": "32px",
									     minWidth: "32px",
									     height: "32px",
									     width: "32px"
								     }}/>
							</Box>)
					}
					<Typography
						sx={{pl: 1, color: isMobile ? theme.palette.text.primary : "unset"}}
						variant={isMobile ? "bodyMBold" : "bodyLBold"}>{firstName} {lastName}
					</Typography></TableCell>
				{!isMobile &&
                    <TableCell><Typography
                        variant={"bodyL"}>{EPlayerPositionToPretty(position)}</Typography></TableCell>
				}
				<TableCell align={"center"}><Typography variant={isMobile ? "bodySBold" : "bodyM"}>{goals}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyM"}>{assists}</Typography></TableCell>
				{isMobile ?
					<TableCell align={"center"}><Typography
						variant={isMobile ? "bodySBold" : "bodyM"}>{yellowCardCount}</Typography></TableCell>
					:
					(!!lastYellowCardRound && yellowCardCount != 0) ?
						<TableCell style={{paddingTop: "12px", paddingBottom: 0}}>
							<Stack alignItems={"center"}>
								<Typography variant={isMobile ? "bodySBold" : "bodyM"}>{yellowCardCount}</Typography>
								<Typography
									variant={isMobile ? "bodySBold" : "podnapis"}>{lastYellowCardRound}.
									krog</Typography>
							</Stack>
						</TableCell>
						:
						<TableCell align={"center"}>
							<Typography variant={isMobile ? "bodySBold" : "bodyM"}>{yellowCardCount}</Typography>
						</TableCell>
				}
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyM"}>{redCardCount}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={isMobile ? "bodySBold" : "bodyM"}>{minutes}</Typography></TableCell>
				{!isMobile && <TableCell align={"center"}><Typography
                    variant={isMobile ? "bodySBold" : "bodyM"}>{appearances}</Typography></TableCell>
				}
				{!isMobile && <TableCell align={"center"}><Typography variant={isMobile ? "bodySBold" : "bodyM"}>
					{calculateAge(dateOfBirth)}
                </Typography></TableCell>
				}
			</TableRow>
			{
				competitionStats.map((competitionStat) => (
					<CompetitionStatisticsClubPlayersSingleCompetitionRow
						key={competitionStat.competitionType}
						open={open}
						competitionStats={competitionStat}
					/>
				))
			}
		</>
	)
}