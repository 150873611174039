import {alpha, Box, useTheme} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {useNavigate} from "react-router-dom";

export const AppBarLogo = () => {
	const theme = useTheme()
	const navigate = useNavigate();

	const isMobile = useIsMobileContext()

	return (
		<Box
			onClick={() => navigate("/")}
			sx={{
				flexShrink: 0,
				display: "flex",
				height: "100%",
				// pt: 1,
				alignItems: "center",
				'&:hover': {
					backgroundColor: alpha(theme.palette.common.white, 0.07),
					cursor: "pointer",
				}
			}}>
			<img src={"/images/GRB.png"} alt={"logo"} style={{width: isMobile ? "36px" : "48px", paddingInline: "16px"}}/>
		</Box>
	)
}
