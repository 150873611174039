import Banner from "../../components/Banner";
import BannerFirebaseDocument from "../../models/firestore/common/BannerFirestoreDto";

export function LandingTopBanner (props: {data: BannerFirebaseDocument}) {
	const {title, imageUrl, createdAt, actionLink} = props.data;

	return(
		<Banner
			title={title}
			imageUrl={imageUrl}
			createdAt={createdAt}
			onClickLink={actionLink}
			isLeftAligned={true}
		/>
	)
}
