import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getNewsArticle} from "firestore/BaseFirestore";
import {ContentElementPageCacheFirebaseDocumentUnion} from "models";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {NewsArticleFooter} from "./NewsArticleFooter";
import {NewsArticleHeader} from "./NewsArticleHeader";
import {ArticleListFirestoreDocument} from "@nitra/nkmb-card-components";


const NewsArticle = () => {
	const {id} = useParams();

	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);
	const [title, setTitle] = useState("");
	const [createdAt, setCreatedAt] = useState<Date>(new Date());
	const [imageUrl, setImageUrl] = useState("");
	const [suggestedArticles, setSuggestedArticles] = useState<ArticleListFirestoreDocument[]>([]);
	const [nextArticle, setNextArticle] = useState<ArticleListFirestoreDocument | undefined>(undefined);

	useEffect(() => {
		if (!id) {
			console.error("No id provided");
			return;
		}
		getNewsArticle(id).then((article) => {
			setContent(article.content);
			setTitle(article.title);
			setCreatedAt(article.createdAt);
			if (article.image1440x680url) setImageUrl(article.image1440x680url);
			setSuggestedArticles(article.suggestedArticles);
			setNextArticle(article.nextArticle)
		}, (error) => {
			console.error(error);
		})
	}, [id])


	return (
		<>
			<NewsArticleHeader title={title} imageUrl={imageUrl} createdAt={createdAt}/>
			<ContentContainer content={content}/>
			<NewsArticleFooter suggestedArticles={suggestedArticles} nextArticle={nextArticle}/>
		</>
	);
}

export default NewsArticle;

