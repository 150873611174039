import {VipBannerFirebaseDto} from "../../models";
import {Box, Stack, Typography, useTheme} from "@mui/material";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {ShareArea} from "@nitra/nkmb-card-components";
import React from "react";

export function TicketLandingMobileHospitalityBanner(props: {
	vipBanner: VipBannerFirebaseDto,
}) {
	const {vipBanner} = props

	const theme = useTheme()
	const {isIos} = useIosContext()

	return (
		<Box sx={{
			backgroundImage: `url(/images/hospitality/skybox/mobile_tickets_vip_box.jpg)`,
			width: "100%",
			pt: "81%",
			height: "unset",
			position: "relative",
			backgroundSize: "cover",
			backgroundPosition: "center",
		}}>
			<div style={{
				backgroundImage: "linear-gradient(to bottom,rgba(0, 0, 0, 0) 60%,rgba(0, 0, 0, 0.8))",
				zIndex: 1,
				position: "absolute", top: 0, left: 0, right: 0, bottom: 0,
				flexGrow: 1
			}}/>
			<Box
				sx={{position: "relative", zIndex: 3, px: 2, color: theme.palette.background.paper, pb: 1}}>
				<Typography
					sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
						pb: 1
					}}
					variant={"h3"}>{vipBanner.title}</Typography>
				<Stack direction={"row"} sx={{alignItems: "center"}}>
					<Typography variant={"bodyL"}>{vipBanner.subtitle}</Typography>
					<Box sx={{
						ml: "auto"
					}}>
						<ShareArea shareUrl={"/hospitality"} brightText={true}/>
					</Box>
				</Stack>
			</Box>
		</Box>
	)
}