import {Stack} from "@mui/material";
import {CalendarCardWithLogic, TimeManagementContextProvider, useIsMobileContext} from "@nitra/nkmb-card-components";
import {CardContainerWithName} from "components/CardContainerWithName";
import {InnerWidthContainer, PaperWidthContainer} from "components/widthControlElements";
import {getSeasonMatches} from "firestore/BaseFirestore";
import {getTicketsPage} from "firestore/specificGetters/TicketRequests";
import {VipBannerFirebaseDto} from "models";
import {TicketFirestoreDocument} from "models/firestore/ticket/TicketFirestoreDocument";
import React, {useEffect, useState} from "react";
import {MatchTicketCard} from "views/tickets/MatchTicketCard";
import Banner from "../../components/Banner";
import {SeasonTicketCard} from "./SeasonTicketCard";
import {TicketLandingMobileHospitalityBanner} from "./TicketLandingMobileHospitalityBanner";


function TicketLanding() {
	const [dayTickets, setDayTickets] = useState<TicketFirestoreDocument[]>([]);
	const [seasonTickets, setSeasonTickets] = useState<TicketFirestoreDocument[]>([]);
	const [vipBanner, setVipBanner] = useState<VipBannerFirebaseDto>({
		title: "Ljudski vrt 5*",
		imageUrl: "/images/hospitality/tribuna.png",
		actionLink: "/hospitality",
		subtitle: "Ekskluzivno doživetje v Ljudskem vrtu",
	});

	const isMobile = useIsMobileContext();

	useEffect(() => {
		get();
	}, []);

	async function get() {
		const response = await getTicketsPage()
		if (response) {
			setDayTickets(response.dayTickets)
			setSeasonTickets(response.seasonTickets)
		}
	}

	return (
		<Stack>
			<InnerWidthContainer>
				{dayTickets.length > 0 &&
                    <CardContainerWithName title={"Dnevne vstopnice"} cardAlignLeft={true}>
						{
							dayTickets.map((data, index) =>
								<MatchTicketCard key={index} data={data}/>
							)
						}
                        <TimeManagementContextProvider
                            getterFunction={getSeasonMatches}
                            limitToCurrentSeason={true}
                        >
                            <CalendarCardWithLogic/>
                        </TimeManagementContextProvider>
                    </CardContainerWithName>
				}
				{seasonTickets.length > 0 &&
                    <CardContainerWithName title={"Sezonske vstopnice"} cardAlignLeft={true}>
						{
							seasonTickets.map((data, index) =>
								<SeasonTicketCard key={index} data={data}/>
							)
						}
                    </CardContainerWithName>
				}
			</InnerWidthContainer>
			{vipBanner &&
                <PaperWidthContainer sx={{mt: 2}}>
					{isMobile ? <TicketLandingMobileHospitalityBanner vipBanner={vipBanner}/> :
						<Banner
							title={vipBanner.title}
							imageUrl={vipBanner.imageUrl}
							subtitle={vipBanner.subtitle}
							onClickLink={vipBanner.actionLink}
							sx={{marginTop: "unset"}}
						/>}
                </PaperWidthContainer>
			}
		</Stack>
	)
}

export default TicketLanding;

