import {alpha, Box, Toolbar, useTheme} from "@mui/material";
import React from "react";
import {AppBarLogo} from "./AppBarLogo";
import {AppBarNavigationButton} from "./AppBarNavigationButton";
import {MenuIconButton} from "./MenuIconButton";
import {navigationTargets} from "./NavigationTargets";
import {SearchBar} from "./SearchBar";
import useAppBarHeightContext from "../ContextProviders/AppBarHeightContext";

export function DesktopAppBar(props: {
	containerRef: React.RefObject<HTMLDivElement>,
	appBarTextRefs: React.RefObject<(HTMLButtonElement | null)[]>,
	hiddenItems: Set<number>,
	handleDrawerToggle: () => void,
	isDrawerOpen: boolean,
	searchIconRef: React.RefObject<HTMLButtonElement>

}) {
	const {containerRef, appBarTextRefs, hiddenItems, handleDrawerToggle, isDrawerOpen, searchIconRef} = props;

	const theme = useTheme();

	const {isScrolled, heightWithoutBlackBarWithPx} = useAppBarHeightContext();

	return (
		<Toolbar
			sx={{
				maxWidth: theme.breakpoints.values.xl,
				width: "100%",
				mx: "auto",
				// mt: isScrolled ? "unset" : "32px",
				height: heightWithoutBlackBarWithPx,
				display: "grid",
				gridTemplateColumns: "auto 1fr auto",
				alignItems: "center",
				pl: "0!important",
				pr: "0!important",
			}}
			ref={containerRef}
		>
			<Box sx={{
				display: "flex",
				alignItems: "center",
				height: "100%",
				direction: "row",
			}}>
				<AppBarLogo/>
				{hiddenItems.size > 0 &&
                    <MenuIconButton onClick={handleDrawerToggle} isDrawerOpen={isDrawerOpen} sx={{
						'&:hover': {
							backgroundColor: alpha(theme.palette.common.white, 0.07),
							cursor: "pointer",
						}
					}}/>}
			</Box>
			<Box
				sx={{
					display: "flex",
					overflow: "hidden",
					justifyContent: "flex-start",
					height: "100%",

				}}
			>
				{navigationTargets
					.filter((item) => item.showInDesktop)
					.map((item, index) =>
						<AppBarNavigationButton
							key={index}
							title={item.title}
							url={item.url}
							sx={{visibility: hiddenItems.has(index) ? "hidden" : "visible"}}
							ref={(el) => (el && appBarTextRefs.current ? (appBarTextRefs.current[index] = el) : null)}
						/>
					)}
			</Box>
			<SearchBar ref={searchIconRef}/>
		</Toolbar>
	)
}
