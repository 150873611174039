import {Stack} from "@mui/material";
import {ClubBanner} from "views/club/ClubBanner";
import {FootballSchoolLandingBody} from "views/footballSchool/FootballSchoolLandingBody";
import {InnerWidthContainer} from "../../components/widthControlElements";
import React from "react";

export function FootballSchoolBasic() {
	return (
		<Stack>
			<ClubBanner
				url={"/images/football-school-banner.png"}
				mainText={"Nogometna šola NK Maribor"}
				alignText={"stretch"}
			/>
			<InnerWidthContainer>
				<FootballSchoolLandingBody/>
			</InnerWidthContainer>
		</Stack>
	)
}

