import {useNavigate} from "react-router-dom";
import {Stack, Typography} from "@mui/material";

export function DesktopNextArticle(props: {
	data: {
		title: string,
		image600x360url: string,
		firestoreId: string
	}
}) {
	const {title, image600x360url, firestoreId} = props.data;
	const navigate = useNavigate()

	return (
		<Stack direction={"row"} spacing={4} justifyContent={"center"} onClick={() => navigate(`../${firestoreId}`)}>
			<div
				style={{
					width: 288,
					height: 140,
					borderRadius: "8px",
					backgroundImage: `url(${image600x360url})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat'
				}}
			/>
			<Stack direction={"column"} spacing={2}>
				<Typography variant={"gumbL"}>Naslednji članek</Typography>
				<Typography variant={"h4"}>{title}</Typography>
			</Stack>
		</Stack>
	)
}