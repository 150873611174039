import {Box, Stack, Typography, useTheme} from "@mui/material";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import typography from "@nitra/nkmb-theme/typography";
import {StatisticsPlayerShooterAppearanceFirestoreDocument, StatisticsPlayerShooterAppearanceFlat} from "models";
import React, {useEffect, useState} from "react";
import {DesktopStatisticsSeasonSelector} from "views/club/statistics/desktop/DesktopStatisticsSeasonSelector";
import {DesktopStatisticsSearchBar} from "views/club/statistics/desktop/player/DesktopStatisticsSearchBar";
import {useNavigate} from "react-router-dom";

const columns: GridColumns<StatisticsPlayerShooterAppearanceFlat> = [
	{
		headerName: "#",
		field: "position",
		type: "number",
		width: 58,
		align: "center",
		headerAlign: "center",
	}, {
		headerName: "ime priimek",
		field: "firstName",
		renderCell: (params) => <Typography variant={"bodyM"}>{params.row.firstName} {params.row.lastName}</Typography>,
		flex: 1,
	}, {
		headerName: "liga",
		field: "league",
		type: "number",
		width: 58,
		align: "center",
		headerAlign: "center",
	}, {
		headerName: "pokal",
		field: "cup",
		type: "number",
		width: 58,
		align: "center",
		headerAlign: "center",
	}, {
		headerName: "evropa",
		field: "uefa",
		type: "number",
		width: 58,
		align: "center",
		headerAlign: "center",
	}, {
		headerName: "skupaj",
		field: "total",
		type: "number",
		width: 58,
		align: "center",
		headerAlign: "center",

	}]

export function DesktopPlayerStatisticsTable(props: {
	title: string,
	players: StatisticsPlayerShooterAppearanceFirestoreDocument[],
}) {
	const {title, players} = props
	const [season, setSeason] = useState(42)
	const [filter, setFilter] = useState("")
	const [filteredPlayers, setFilteredPlayers] = useState<StatisticsPlayerShooterAppearanceFlat[]>([])

	const theme = useTheme()
	const navigate = useNavigate();


	useEffect(() => {
		setFilteredPlayers(players
			.filter(player => player.data.some(data => data.season == season))
			.filter(player => (player.firstName + " " + player.lastName).toLowerCase().includes(filter.toLowerCase()))
			.map(player => {
					const data = player.data.find(data => data.season == season)
					return {
						key: player.key,
						firstName: player.firstName,
						lastName: player.lastName,
						league: data?.league,
						cup: data?.cup,
						uefa: data?.uefa,
						total: data?.total,
					} as StatisticsPlayerShooterAppearanceFlat
				}
			)
			.sort((a, b) => b.total - a.total)
			.map((player, index) => {
				return {
					...player,
					position: index + 1
				}
			})
		)
	}, [season, players, filter]);

	return (
		<Stack>
			<Stack alignItems={"flex-start"} sx={{pb: 2}}>
				<DesktopStatisticsSeasonSelector isOnPlayers={true} season={season} setSeason={setSeason}/>
			</Stack>

			<Stack direction={"row"} sx={{pb: 1}}>
				<Typography variant={"section"}>{title}</Typography>
				<DesktopStatisticsSearchBar filter={filter} setFilter={setFilter}/>
			</Stack>
			<Box sx={{
				borderRadius: "8px",
				backgroundColor: theme.palette.background.default,
				boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",

				py: 1
			}}>

				<DataGrid
					getRowId={row => row.key}
					columns={columns}
					rows={filteredPlayers}

					hideFooterPagination={true}
					hideFooter={true}
					autoHeight={true}
					disableColumnMenu={true}
					density={"compact"}

					onRowClick={(params) => {
						navigate("/club/statistics/player/" + params.id)
					}}

					sx={{
						borderRadius: 0,
						backgroundColor: theme.palette.background.default,
						color: "#676767",
						'& .MuiDataGrid-columnSeparator': {
							display: "none"
						},
						'& .MuiDataGrid-columnHeaderTitle': {
							...typography.bodySBold,
						},
						"& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
							height: "30px",
							backgroundColor: theme.palette.background.paper,
						},

					}}
				/>

			</Box>
		</Stack>
	)
}
