import {
	PlayerPlayedMatchStatsFirebaseDocument,
	PlayerProfileFirebaseDocument,
	PlayerProfileMatchFirebaseDocument,
	PlayerStatsFirebaseDocument
} from "models";

export function playerProfileMapper(payload: any): PlayerProfileFirebaseDocument {
	return {
		isActive: payload.isActive,
		image680x680url: payload.image680x680url,
		image271x271url: payload.image271x271url,

		firstName: payload.firstName,
		lastName: payload.lastName,
		jerseyNumber: payload.jerseyNumber,
		nationality: payload.nationality,

		playerPosition: payload.playerPosition,
		locationOfBirth: payload.locationOfBirth,
		dateOfBirth: payload.dateOfBirth?.toDate(),
		age: payload.age,
		height: payload.height,
		weight: payload.weight,

		previousClub: payload.previousClub,
		contractUntil: payload.contractUntil?.toDate(),

		appearances: payload.appearances,
		goals: payload.goals,
		firstMatch: playerProfileMatchMapper(payload.firstMatch),
		lastMatch: playerProfileMatchMapper(payload.lastMatch),

		relatedArticles: payload.relatedArticles.map((article: any) => {
			return {
				...article,
				createdAt: article.createdAt.toDate(),
			}
		}),
	}
}

export function playerProfileMatchMapper(payload: any): PlayerProfileMatchFirebaseDocument | undefined {
	if (!payload) return undefined
	return {
		common: {
			key: payload.common.key,
			competition: payload.common.competition,
			date: payload.common.date.toDate(),
			stadium: payload.common.stadium,
			round: payload.common.round,
			homeTeamName: payload.common.homeTeamName,
			homeTeamLogoUrl: payload.common.homeTeamLogoUrl,
			awayTeamName: payload.common.awayTeamName,
			awayTeamLogoUrl: payload.common.awayTeamLogoUrl,
			isDone: payload.common.isDone,
			score: payload.common.score,
			liveTickerKey: payload.common.liveTickerKey,
			reportId: payload.common.reportId,
			buyTicketLink: payload.common.buyTicketLink,
			lastMatch: payload.common.lastMatch,
		},
		stats: {
			minutesPlayed: payload.stats.minutesPlayed,
			goals: payload.stats.goals,
			assists: payload.stats.assists,
			cards: payload.stats.cards,
		},
	}
}

export function playerProfileStatsMapper(payload: any): PlayerStatsFirebaseDocument {
	return {
		firstMatch: playerProfileMatchMapper(payload.firstMatch),
		lastMatch: playerProfileMatchMapper(payload.lastMatch),
		seasons: payload.seasons.map((seasonStat: any) => {
			return {
				season: seasonStat.season,
				matches: seasonStat.matches.map((item: any) => playerPlayedMatchStatsFirebaseDocumentMapper(item)),
			}
		}),
	}
}

export function playerPlayedMatchStatsFirebaseDocumentMapper(payload: any): PlayerPlayedMatchStatsFirebaseDocument {
	return {
		competitionType: payload.competitionType,
		round: payload.round,
		date: payload.date.toDate(),
		homeTeamName: payload.homeTeamName,
		awayTeamName: payload.awayTeamName,
		score: payload.score,
		isFirstEleven: payload.isFirstEleven,
		goalCount: payload.goalCount,
		assistCount: payload.assistCount,
		gotYellowCard: payload.gotYellowCard,
		gotRedCard: payload.gotRedCard,
		minutesPlayed: payload.minutesPlayed,
		subbedIn: payload.subbedIn,
		subbedOut: payload.subbedOut,
	}
}