import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";

import {
	ClubStatisticsMatchFirestoreDocument,
	ClubStatisticsSeasonFirestoreDocument,
	PlayerStatisticsFirestoreDocument,
	StatisticsPlayerShooterAppearanceDataFirestoreDocument,
	StatisticsPlayerShooterAppearanceFirestoreDocument
} from "models";

export async function getPlayerScoresAppearances(): Promise<PlayerStatisticsFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, 'statisticsCache'), 'players'));

	if (!snapshot.exists()) throw new Error('Player statistics not found');

	const data = snapshot.data();

	return {
		scorers: data.scorers.map((item: any) => ({
			key: item.key,
			firstName: item.firstName,
			lastName: item.lastName,
			data: item.data.map((item: any) => ({
				season: item.season,
				league: item.league,
				cup: item.cup,
				uefa: item.uefa,
				total: item.total
			} as StatisticsPlayerShooterAppearanceDataFirestoreDocument))
		} as StatisticsPlayerShooterAppearanceFirestoreDocument)),
		appearances: data.appearances.map((item: any) => ({
			key: item.key,
			firstName: item.firstName,
			lastName: item.lastName,
			data: item.data.map((item: any) => ({
				season: item.season,
				league: item.league,
				cup: item.cup,
				uefa: item.uefa,
				total: item.total
			} as StatisticsPlayerShooterAppearanceDataFirestoreDocument))
		} as StatisticsPlayerShooterAppearanceFirestoreDocument))
	};
}


export async function getClubStatistics(season: number): Promise<ClubStatisticsSeasonFirestoreDocument> {
	const payload = await getDoc(doc(collection(db, 'statisticsCache'), 'club', 'seasons', season.toString()));

	if (!payload.exists()) throw new Error(`Club statistics for season ${season} not found`);

	const data = payload.data()

	return {
		name: data.name,
		season: data.season,
		standings: data.standings,
		matches: data.matches.map((item: any) => ({
			homeName: item.homeName,
			awayName: item.awayName,
			round: item.round,
			result: item.result,
			resultFirstHalf: item.resultFirstHalf,
			date: item.date.toDate(),
			spectators: item.spectators,
			lineup: item.lineup,
			goals: item.goals
		} as ClubStatisticsMatchFirestoreDocument)),
	} as ClubStatisticsSeasonFirestoreDocument;
}
