import {CardContent, useTheme} from "@mui/material";
import {CardOverImageTitle, CardWithShadow, ShareArea, useIsMobileContext} from "@nitra/nkmb-card-components";
import {TicketFirestoreDocument} from "models";
import {ETicketLevel} from "models/enums/ETicketLevel";
import React, {useEffect, useState} from "react";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function SeasonTicketCard(props: {
	data: TicketFirestoreDocument
}) {
	const {title, ticketLevel, actionLink} = props.data

	const [imageUrl, setImageUrl] = useState("")

	const isMobile = useIsMobileContext();
	const {isIos} = useIosContext()
	const theme = useTheme();

	useEffect(() => {
		switch (ticketLevel) {
			case ETicketLevel.NORMAL:
				if (isMobile) {
					setImageUrl("/images/sezonska-tava-mobile.png")
				} else {
					setImageUrl("/images/sezonska-tava.png")
				}
				break;
			case ETicketLevel.VPS:
				if (isMobile) {
					setImageUrl("/images/vps_seats_mobile_tickets.png")
				} else {
					setImageUrl("/images/sezonska-poslovni-sedezi.png")
				}
				break;
			case ETicketLevel.SKYBOX:
				if (isMobile) {
					setImageUrl("/images/skybox_tickets_mobile.jpg")
				} else {
					setImageUrl("/images/sezonska-privatna-loza.png")
				}

				break;
		}
	}, [ticketLevel]);


	return (
		<CardWithShadow
			sx={{display: "flex", flexDirection: "column", position: "relative"}}
			cardHeightOverride={isMobile ? 141 : 360}
			fixedWidth={isMobile ? undefined : ticketLevel === ETicketLevel.NORMAL ? 600 : 288}
			fullSizeImageUrl={imageUrl}
			onClickNavigateUrl={actionLink}
		>
			<CardContent sx={{mt: "auto", zIndex: 1}} style={{paddingBottom: isMobile ? 8 : 16}}>
				<CardOverImageTitle
					title={title}
					variant={isMobile ? "h4" : "podnaslovM"}
					sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
				/>
				<ShareArea shareUrl={actionLink} brightText={true} sx={{
					position: "absolute",
					right: 16,
					bottom: 16
				}}/>
			</CardContent>
		</CardWithShadow>
	)
}
