import {Box} from "@mui/material";
import {VideoContentElementPageCacheFirebaseDocument} from "models";

export function VideoContent(props: { element: VideoContentElementPageCacheFirebaseDocument }) {
	const {youtubeId} = props.element;
	return (
		<Box
			sx={{
				width: '100%',
				paddingTop: '56.25%',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}
			>
				<iframe
					style={{
						width: '100%',
						height: '100%',
						maxWidth: '690px',
						maxHeight: '388px',
					}}
					src={`https://www.youtube.com/embed/${youtubeId}`}
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen>
				</iframe>
			</Box>
		</Box>
	)
}
