import {Stack, Typography, useTheme} from "@mui/material";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function PlayerProfileBannerLeftColumnMobile() {
	const player = usePlayerDetailsContext();
	const theme = useTheme();
	const {isIos} = useIosContext()

	const {jerseyNumber, firstName, lastName} = player!;

	return (
		<Stack sx={{pl: 4, height: "100%", color: theme.palette.primary.main}}>
			<Typography variant={"h2"} sx={{pt: 5,
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{jerseyNumber}</Typography>
			<Stack sx={{pt: 4}}>
				<Typography variant={"h5"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>{firstName}</Typography>
				<Typography variant={"h4"} sx={{
					[theme.breakpoints.down("sm")]: isIos && {
						'@media (-webkit-min-device-pixel-ratio: 2)': {
							fontWeight: 500, // Adjust font weight for iPhones
						},
					},
				}}>{lastName}</Typography>
			</Stack>
		</Stack>
	)
}
