import {createContext, PropsWithChildren, useContext, useEffect, useState} from "react";
import {useCompetitionViewContext} from "./CompetitionContext";
import {getCompetitionsStatistics} from "../../../firestore";
import {
	CompetitionStatisticsClubFirestoreDocument,
	CompetitionStatisticsContainerFirestoreDocument,
	CompetitionStatisticsPlayerFirestoreDocument,
	CompetitionStatisticsStreaksFirestoreDocument
} from "../../../models";
import {ECompetitionType} from "@nitra/nkmb-card-components";
import useCompetitionFilterButtonsContext
	from "../../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";

const CompetitionStatisticsContext = createContext<{
	seasonNumber: number,
	competitionStatBlock: CompetitionStatisticsClubFirestoreDocument | null,
	playersData: CompetitionStatisticsPlayerFirestoreDocument[],
	streaks: CompetitionStatisticsStreaksFirestoreDocument | null,
} | null>(null);

export default function useCompetitionStatisticsContext() {
	const context = useContext(CompetitionStatisticsContext);
	if (context === null) {
		throw new Error('useCompetitionStatisticsContext must be used within a CompetitionStatisticsContextProvider');
	}
	return context;
}

export function CompetitionStatisticsContextProvider(props: PropsWithChildren<{}>) {
	const {season} = useCompetitionViewContext()
	const [seasonNumber, setSeasonNumber] = useState<number>(42);
	const [data, setData] = useState<CompetitionStatisticsContainerFirestoreDocument | null>(null);

	const [competitionStatBlock, setCompetitionStatBlock] = useState<CompetitionStatisticsClubFirestoreDocument | null>(null);
	const [playersData, setPlayersData] = useState<CompetitionStatisticsPlayerFirestoreDocument[]>([]);
	const [streaks, setStreaks] = useState<CompetitionStatisticsStreaksFirestoreDocument | null>(null);

	const {filteredCompetitions} = useCompetitionFilterButtonsContext()
	const {scoreAssistsSelectedCompetition} = useCompetitionViewContext()

	useEffect(() => {
		if (!data) return
		setCompetitionStatBlock(data.blocks.find((block) =>
			filteredCompetitions.length === 0
				? ECompetitionType.FIRST_LEAGUE
				: filteredCompetitions.includes(block.competitionType)) ?? null)

		const filteredPlayerStats = data.players
			.filter((player) =>
				player.competitionStats.some((stat) => filteredCompetitions.length === 0 ? true : filteredCompetitions.includes(stat.competitionType)))
			.map((player) => ({
				...player,
				competitionStats: player.competitionStats.filter((stat) => filteredCompetitions.length === 0 ? true : filteredCompetitions.includes(stat.competitionType))
			}))

		setPlayersData(filteredPlayerStats)
	}, [data, filteredCompetitions]);

	useEffect(() => {
		if (!data) return
		setStreaks(data.streaks)
	}, [data]);

	useEffect(() => {
		if (season.length > 3) {
			setData(null)
			setStreaks(null)
			setCompetitionStatBlock(null)
			setPlayersData([])
			getCompetitionsStatistics(season.split("/")[0])
				.then((payload) =>
					setData(payload)
				)
			setSeasonNumber(parseInt(season.split("/")[0]))
		}
	}, [season]);

	useEffect(() => {
		if (data) {
			if (scoreAssistsSelectedCompetition === ECompetitionType.ANY_UEFA) {
				setCompetitionStatBlock(data.blocks.find((block) => block.competitionType === scoreAssistsSelectedCompetition) ?? null)
			} else {
				setCompetitionStatBlock(data.blocks.find((block) => block.competitionType === scoreAssistsSelectedCompetition) ?? null)
			}
		}
	}, [scoreAssistsSelectedCompetition]);

	return (
		<CompetitionStatisticsContext.Provider value={{
			seasonNumber,
			competitionStatBlock,
			playersData,
			streaks
		}}>
			{props.children}
		</CompetitionStatisticsContext.Provider>
	)
}