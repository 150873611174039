import {Button, Stack, Typography} from "@mui/material";
import React from "react";

export function StadiumSchema() {
	function handleBuyTicket() {
		window.open("https://www.vijolcnabajta.si/si/vstopnice.html", "_blank")
	}

		return (
		<Stack alignItems={"stretch"} spacing={2}>
			<img src={"/images/club/stadium-schema.png"} alt={"Shema stadiona Ljudski vrt"}/>
			<Stack direction={"row"} spacing={2} justifyContent={"center"}>
				<Button variant={"contained"} sx={{borderRadius: "24px"}}>
					<Typography>Natisni shemo</Typography>
				</Button>
				<Button variant={"contained"} sx={{borderRadius: "24px"}} onClick={handleBuyTicket}>
					<Typography>Kupi vstopnico</Typography>
				</Button>
			</Stack>
		</Stack>
	)
}
