import {Button, SxProps, Typography, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";

export function VipButton(props: {
	label: string,
	href?: string,
	navigateLink?: string,
	sx?: SxProps,
}) {
	const {label, href, navigateLink} = props;

	const theme = useTheme();
	const navigate = useNavigate()

	return (
		<Button
			variant={"outlined"}
			color={"inherit"}
			sx={{
				borderRadius: "20px",
				color: theme.palette.background.paper,
				...props.sx
			}}
			{...href!! ? {href: href} : {onClick: () => navigate(navigateLink!!)}}
		>
			<Typography sx={{color: "background.paper"}} variant={"gumbL"}>{label}</Typography>
		</Button>
	)
}
