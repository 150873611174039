import {Stack} from "@mui/material";
import {Redirect} from "@nitra/nkmb-card-components";
import {INavigationBarTab} from "components/NavigationBar/INavigationBarTab";
import {NavigationBar} from "components/NavigationBar/NavigationBar";


import React from "react";
import {Route, Routes} from "react-router-dom";
import {ClubStatistics} from "views/club/statistics/ClubStatistics";
import {ClubTrophy} from "views/club/trophies/ClubTrophy";
import {ClubFans} from "./ClubFans";
import {ClubHistory} from "./history/ClubHistory";
import {DecadeDetailsPage} from "./history/DecadeDetailsPage";
import {LegendProfile} from "./history/LegendProfile";
import {WarriorProfile} from "./history/WarriorProfile";
import {ClubStadiumRouter} from "./stadium/ClubStadiumRouter";
import {ClubSymbolsRouter} from "./symbols/ClubSymbolsRouter";
import {ClubTrophies} from "./trophies/ClubTrophies";
import {ClubStatisticsPlayerPage} from "./statistics/ClubStatisticsPlayerPage";


const tabs: INavigationBarTab[] = [
	{label: "Zgodovina", url: "history"},
	{label: "Lovorike", url: "trophies"},
	{label: "Ljudski vrt", url: "stadium"},
	{label: "Navijači", url: "fans"},
	{label: "Simboli", url: "symbols"},
	{label: "Statistika", url: "statistics"}
]

export function ClubRouter() {
	return (
		<Stack>
			<NavigationBar tabs={tabs}/>
			<Routes>
				<Route path={"/"} element={<Redirect to={"history"}/>}/>
				<Route path="history/*" element={<ClubHistory/>}/>
				<Route path={"history/legends/:key"} element={<LegendProfile/>}/>
				<Route path={"history/warriors/:key"} element={<WarriorProfile/>}/>
				<Route path={"history/decades/:key"} element={<DecadeDetailsPage/>}/>
				<Route path="trophies" element={<ClubTrophies/>}/>
				<Route path="trophies/:key" element={<ClubTrophy/>}/>
				<Route path="stadium/*" element={<ClubStadiumRouter/>}/>
				<Route path="fans" element={<ClubFans/>}/>
				<Route path="symbols/*" element={<ClubSymbolsRouter/>}/>
				<Route path="statistics/player/:key" element={<ClubStatisticsPlayerPage/>}/>
				<Route path="statistics/*" element={<ClubStatistics/>}/>
			</Routes>
		</Stack>
	);
}
