import {Button, Typography, useTheme} from "@mui/material";
import React from "react";

export function TeamNavigationBarDesktopButton(props: {
	label: string,
	selected: boolean,
	onClick: () => void
}) {
	const theme = useTheme()

	return (
		<Button variant={"text"}
		        onClick={props.onClick}
		        sx={{
			        color: props.selected ? "black" : theme.palette.background.paper,
			        backgroundColor: props.selected ? theme.palette.background.paper : "black",
			        borderRadius: 0,
			        '&:hover': {
				        backgroundColor: props.selected ? theme.palette.background.paper : "black",
			        }
		        }}>
			<Typography variant={"gumbL"}>{props.label}</Typography>
		</Button>
	)
}
