import {MatchCallToActionButton, MatchListElementFirestoreDocument, toNumericDate} from "@nitra/nkmb-card-components";
import {useCompetitionViewContext} from "../../views/competitions/common/CompetitionContext";
import {alpha, Box, Stack, Typography, useTheme} from "@mui/material";
import {getCompetitionNameFromType} from "../../utilities/enumToName";
import {MatchVsColumn} from "./MatchVsColumn";

export function SingleMatchMobileContainer(props: { match: MatchListElementFirestoreDocument }) {
	const {match} = props;

	const {currentRef, firstRef, firstMatchKey, currentMatchKey} = useCompetitionViewContext();
	const theme = useTheme()

	function isFirst() {
		return match.key === firstMatchKey;
	}

	function isCurrent() {
		return match.key === currentMatchKey;
	}

	return (
		<Stack
			ref={isFirst() ? firstRef : isCurrent() ? currentRef : null}
		>
			<Stack direction={"row"} justifyContent={"flex-start"} alignItems="center" alignContent={"center"}
			       sx={{backgroundColor: theme.palette.background.default, height: "29px"}}>
				<Typography variant={"bodyS"} sx={{pl: 2}}>{toNumericDate(match.date)}</Typography>
				<Box sx={{
					border: "1px solid",
					borderColor: alpha(theme.palette.primary.light, 0.2),
					height: "16px",
					mx: 1,
				}}/>
				<Typography variant={"bodyS"}
				            sx={{color: "#676767"}}>{getCompetitionNameFromType(match.competition)}</Typography>
			</Stack>
			<Stack>
				<Box sx={{
					gridTemplateColumns: "1fr 65px 1fr",
					display: "grid",
					alignItems: "center",
					justifyContent: "center",
					pt: 2,
					pb: 1,
				}}>
					<Typography sx={{ml: "auto", pr: 2}} variant={"bodyMBold"}>{match.homeTeamName}</Typography>
					<MatchVsColumn match={match}/>
					<Typography sx={{mr: "auto", pl: 2}} variant={"bodyMBold"}>{match.awayTeamName}</Typography>
				</Box>
				<MatchCallToActionButton
					reportId={match.reportId}
					liveTickerKey={match.liveTickerKey}
					buyTicketLink={match.buyTicketLink}
				/>
			</Stack>

		</Stack>
	)
}