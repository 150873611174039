import {Stack, Typography, useTheme} from "@mui/material";
import {getClubStatistics} from "firestore/specificGetters/StatisticsFirestore";
import {ClubStatisticsMatchFirestoreDocument, ClubStatisticsSeasonStandingFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {MobileClubStatisticsMatchList} from "views/club/statistics/mobile/club/MobileClubStatisticsMatchList";
import {MobileClubStatisticsStandings} from "views/club/statistics/mobile/club/MobileClubStatisticsStandings";

export function MobileClubStatistics(props: {
	season: number
}) {
	const {season} = props;

	const [standings, setStandings] = useState<ClubStatisticsSeasonStandingFirestoreDocument[]>([])
	const [matches, setMatches] = useState<ClubStatisticsMatchFirestoreDocument[]>([])

	const theme = useTheme()

	useEffect(() => {
		get()
	}, [season]);

	async function get() {
		const payload = await getClubStatistics(season)

		setStandings(payload.standings)
		setMatches(payload.matches)
	}

	return (
		<Stack sx={{backgroundColor: theme.palette.background.default}}>
			<Typography variant={"podnaslovXS"} sx={{pl: 2, pt: 4, pb: 2}}>Lestvica</Typography>
			<MobileClubStatisticsStandings standings={standings}/>
			<Typography variant={"podnaslovXS"} sx={{pl: 2, pt: 4, pb: 2}}>Tekme</Typography>
			<MobileClubStatisticsMatchList matches={matches} season={season}/>
		</Stack>
	)
}


