import {useNavigate} from "react-router-dom";
import {Grid, Typography, useTheme} from "@mui/material";

import {VipButton} from "./VipButton";
import {LargeImageCard} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function BasicVipElement(props: {
	imageTitle: string,
	imageUrl: string,
	link: string,

	textTitle: string,
	textBody: string,

	buttonLabel: string,
}) {
	const {imageTitle, imageUrl, link, textTitle, textBody, buttonLabel} = props;

	const navigate = useNavigate()
	const theme = useTheme();
	const {isIos} = useIosContext()

	return (
		<Grid
			item
			container
			direction={"row"}
			justifyContent="center"
			alignItems="flex-start"
			spacing={2}
		>
			<Grid item xs={12} md={12} lg={6}>
				<LargeImageCard
					title={imageTitle}
					imageUrl={imageUrl}
					onClick={() => navigate(link)}/>
			</Grid>
			<Grid item container direction="column"
			      justifyContent="space-around"
			      alignItems="flex-start"
			      xs={12} md={12} lg={6} xl={6}
			      spacing={2}
			>
				<Grid item xs={2}>
					<Typography variant={"h3"} sx={{
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}} color={"#FFF"}>{textTitle}</Typography>
				</Grid>
				<Grid item xs={8}>
					<Typography variant={"body1"} align={"justify"} color={"#FFF"}>{textBody}</Typography>
				</Grid>
				<Grid item xs={2} sx={{color: theme.palette.background.paper}}>
					<VipButton label={buttonLabel} navigateLink={link}/>
				</Grid>
			</Grid>
		</Grid>
	)
}
