import {Box, Grid, SxProps, Typography, useTheme} from "@mui/material";
import {ClubStatisticsMatchFirestoreDocument} from "models";
import React, {PropsWithChildren} from "react";

const columnStyle: SxProps = {
	width: 288,
}

export function DesktopClubStatisticsSelectedMatchContainer(props: {
	selectedMatch: ClubStatisticsMatchFirestoreDocument | null,
	season: number,
}) {
	const {selectedMatch, season} = props;

	if (!selectedMatch) return null;

	return (
		<Box sx={{pl: 3}}>
			<Typography variant={"podnaslovL"}>{selectedMatch.homeName} - {selectedMatch.awayName}</Typography>
			<Grid container
			      sx={{pt: 3}}
			      spacing={3}>
				<Grid item sx={columnStyle}>
					<DesktopClubStatisticsSelectedMatchTableWithTitle title={"Statistika"}>
						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"tekma"}/>
						<DesktopClubStatisticsSelectedMatchTableRow
							leftText={`${selectedMatch.homeName} - ${selectedMatch.awayName}`}/>

						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"sezona"}/>
						<DesktopClubStatisticsSelectedMatchTableRow leftText={`${season}/${season + 1}`}/>

						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"datum"}/>
						<DesktopClubStatisticsSelectedMatchTableRow
							leftText={selectedMatch.date.toLocaleString("sl-sl", {
								day: "2-digit",
								month: "long",
								year: "numeric"
							})}/>

						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"kolo"}/>
						<DesktopClubStatisticsSelectedMatchTableRow leftText={selectedMatch.round.toString()}/>

						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"rezultat"}/>
						<DesktopClubStatisticsSelectedMatchTableRow
							leftText={`${selectedMatch.result} (${selectedMatch.resultFirstHalf})`}/>

						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"gledalci"}/>
						<DesktopClubStatisticsSelectedMatchTableRow leftText={selectedMatch.spectators.toString()}/>
					</DesktopClubStatisticsSelectedMatchTableWithTitle>
				</Grid>
				<Grid item sx={columnStyle}>
					<DesktopClubStatisticsSelectedMatchTableWithTitle title={"Postava"}>
						<DesktopClubStatisticsSelectedMatchTableRow isGrey={true} leftText={"ime priimek"}
						                                            rightText={"status"}/>
						{selectedMatch.lineup.map((player) =>
							<DesktopClubStatisticsSelectedMatchTableRow key={player.player} leftText={player.player}
							                                            rightText={player.isSubstitute ? "menjava" : "11"}/>
						)}
					</DesktopClubStatisticsSelectedMatchTableWithTitle>
				</Grid>
				{selectedMatch.goals.length > 0 &&
                    <Grid item sx={columnStyle}>
                        <DesktopClubStatisticsSelectedMatchTableWithTitle title={"Strelci"}>
							{selectedMatch.goals.map((goal) =>
								<Box key={goal.result}>
									<DesktopClubStatisticsSelectedMatchTableRow isGrey leftText={goal.playerName}/>
									<DesktopClubStatisticsSelectedMatchTableRow leftText={goal.result}/>
								</Box>
							)}
                        </DesktopClubStatisticsSelectedMatchTableWithTitle>
                    </Grid>
				}
			</Grid>
		</Box>
	)
}

function DesktopClubStatisticsSelectedMatchTableWithTitle(props: PropsWithChildren<{ title: string }>) {
	const theme = useTheme()


	return (
		<Box sx={{}}>
			<Typography variant={"podnaslovM"}>{props.title}</Typography>
			<Box sx={{
				borderRadius: 1,
				backgroundColor: theme.palette.background.default,
				boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.12)",

				py: 1
			}}>
				{props.children}
			</Box>
		</Box>
	)
}

function DesktopClubStatisticsSelectedMatchTableRow(props: {
	isGrey?: boolean,
	leftText: string,
	rightText?: string,
}) {
	const theme = useTheme()

	return (
		<Box sx={{
			backgroundColor: props.isGrey ? theme.palette.background.paper : theme.palette.background.default,
			display: "flex",
			px: 1,
			height: "30px",
			justifyContent: "flex-start",
			alignItems: "center",
			gap: 1,
			alignSelf: "stretch",
			borderBottom: "1px solid #D9D9D9",
		}}>
			<Typography variant={props.isGrey ? "bodySBold" : "bodyS"}
			            sx={{color: props.isGrey ? "#676767" : null}}>{props.leftText}</Typography>
			{props.rightText &&
                <Typography sx={{ml: "auto"}} variant={"bodyS"}>{props.rightText}</Typography>
			}
		</Box>
	)
}
