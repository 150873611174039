import {Stack} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import {GetCompetitionHistories} from "firestore/BaseFirestore";
import {CompetitionHistoryFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";

import {ClubBanner} from "../ClubBanner";
import {CompetitionHistoryContainer} from "./CompetitionHistoryContainer";

export function ClubTrophies() {
	const [competitionHistories, setCompetitionHistories] = useState<CompetitionHistoryFirestoreDocument[]>([])

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		GetCompetitionHistories()
			.then((data) => {
					setCompetitionHistories(
						data.competitionHistories.sort((a, b) => a.showIndex - b.showIndex)
							.map((competitionHistory) => ({
									...competitionHistory,
									seasonResults: competitionHistory.seasonResults
										.sort((a, b) =>
											parseInt(a.season.slice(0, 4)) - parseInt(b.season.slice(0, 4))
										)
								})
							)
					)
				}
			)
	}

	return (
		<Stack>
			<ClubBanner
				url={"/images/club/banners/trophies.png"}
				mainText={"Lovorike"}
			/>
			<InnerWidthContainer>
				<Stack>
					{competitionHistories
						.map((competitionHistory, index) =>
							<CompetitionHistoryContainer key={index} competitionHistory={competitionHistory}/>
						)}
				</Stack>
			</InnerWidthContainer>
		</Stack>
	)
}

