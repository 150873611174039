import React, {useEffect, useState} from "react";
import {SupportStaffListFirestoreDocument} from "../../models";
import {getSupportStaff} from "../../firestore";
import {Grid, Stack} from "@mui/material";
import {SupportStaffDesktopCard} from "@nitra/nkmb-card-components";

export function SupportStaffDesktopView() {
	const [staff, setStaff] = useState<SupportStaffListFirestoreDocument[]>([])

	async function getStaff() {
		getSupportStaff().then((payload) => {
			setStaff(payload.staff.sort((a, b) => a.position - b.position))
		})
	}

	useEffect(() => {
		getStaff()
	}, []);

	return (
		<Stack direction={"column"} sx={{
			px: {xs: 2, md: 3},
			mx: 0,
		}}>
			<Stack direction={"column"} sx={{width: "100%", pt: 4,}}>
				<Grid container spacing={2} justifyContent={"space-between"}>
					{staff.map((staffMember, index) =>
						<Grid key={index} item
						      xs={6}
						      lg={4}
						      xl={3}
						      sx={{marginRight: index === staff.length - 1 ? "auto" : "unset",}}
						>
							<SupportStaffDesktopCard key={index} staff={staffMember}/>
						</Grid>
					)}
				</Grid>
			</Stack>
		</Stack>
	)
}