export const VipTexts = {
	basic: {
		vps: {
			imageTitle: "Poslovni sedež",
			imageUrl: "/images/hospitality/vps/vps1.png",
			link: "/hospitality/vps",
			textTitle: "Več kot 90 minut nogomenta",
			textBody: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.",
			buttonLabel: "Izbira sedeža",
		},
		skybox: {
			imageTitle: "Privatna loža",
			imageUrl: "/images/hospitality/skybox/skybox1.png",
			link: "/hospitality/skybox",
			textTitle: "loža pod streho stare tribune",
			textBody: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.",
			buttonLabel: "Izbira lože",
		}
	},
	vps: {
		banner: {
			title: "NK Maribor 5*",
			subtitle: "Poslovni sedeži"
		},
		title1: "Poslovni sedeži",
		body1: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.\n\nFootball’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement.",
		title2: "Poslovni sedeži",
		body2: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.\n\nFootball’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement.",
		imageUrls: [
			"/images/hospitality/vps/Rectangle 63.png",
			"/images/hospitality/vps/Rectangle 64.png",
			"/images/hospitality/vps/Rectangle 65.png",
			"/images/hospitality/vps/Rectangle 66.png",
			"/images/hospitality/vps/Rectangle 67.png",
			"/images/hospitality/vps/Rectangle 68.png",
			"/images/hospitality/vps/Rectangle 69.png",
			"/images/hospitality/vps/Rectangle 70.png",
			"/images/hospitality/vps/Rectangle 71.png",
			"/images/hospitality/vps/Rectangle 72.png",
		]
	},
	skybox: {
		banner: {
			title: "NK Maribor 5*",
			subtitle: "Privatne lože"
		},
		title1: "Tvoja dnevna soba pod streho stare tribune 365 dni v letu",
		body1: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.\n\nFootball’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement.",
		title2: "Poslovni sedeži",
		body2: "Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.\n\nFootball’s more than just a game, it’s the build-up, the anticipation, the excitement. It’s being waited on hand and foot, watching all the action from some of the best seats in the house.Football’s more than just a game, it’s the build-up, the anticipation, the excitement.",
		imageUrls: [
			"/images/hospitality/skybox/Rectangle 65.png",
			"/images/hospitality/skybox/Rectangle 66.png",
			"/images/hospitality/skybox/Rectangle 67.png",
			"/images/hospitality/skybox/Rectangle 73.png",
			"/images/hospitality/skybox/Rectangle 74.png",
			"/images/hospitality/skybox/Rectangle 75.png",
			"/images/hospitality/skybox/Rectangle 76.png",
		]
	}
}
