import {Stack} from "@mui/material";
import {EPlayerSidebarStatType} from "@nitra/nkmb-card-components";
import React from "react";
import {SideCardWithPlayerStats} from "views/competitions/playerStatSidebar/SideCardWithPlayerStats";

/**
 * Right stack for competitions desktop view, displaying shooters and assists
 * @constructor
 */
export function CompetitionsRightStack() {
	return (
		<Stack
			direction={"column"}
			spacing={3}
			sx={{
				pt: 8
			}}
		>
			<SideCardWithPlayerStats type={EPlayerSidebarStatType.GOAL}/>
			<SideCardWithPlayerStats type={EPlayerSidebarStatType.ASSIST}/>
		</Stack>
	)
}
