import {CardRouter, ShopItemCardDto, useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import {PropsWithChildren} from "react";
import {CardContainerWithName} from "../../components/CardContainerWithName";


const LandingShopRow = (props: PropsWithChildren<{
	shopItems: ShopItemCardDto[]
}>) => {
	const {shopItems} = props;
	const isMobile = useIsMobileContext();

	return (
		<InnerWidthContainer>
			<CardContainerWithName
				innerSx={{
					flexWrap: "nowrap",
					pb: 4,
					overflow: "visible",
					overflowX: "auto",
					justifyContent: "flex-start",
				}}
				title={"Vijol'čna bajta"}
				titleClickAction={() => window.open("https://www.vijolcnabajta.si/si.html", "_blank")}>
				{shopItems.map((item, index) =>
					<CardRouter key={index} data={item}/>
				)}
			</CardContainerWithName>
		</InnerWidthContainer>
	)
}

export default LandingShopRow
