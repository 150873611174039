import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import {FullWidthContainer, InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";


export function VipLanding() {
	const theme = useTheme()
	const navigate = useNavigate()


	const whiteOutlinedButtonStyle = {
		color: theme.palette.background.default,
		borderColor: theme.palette.background.default,
		borderRadius: 24
	}

	const isMobile = useIsMobileContext()
	const {isIos} = useIosContext()

	return (
		<FullWidthContainer>
			<Stack alignItems={"center"}>
				{/* Če se odtrani minHeight in backgroundSize se slika ne resiza in
				se lahko potem statično nastavijo puščice in gumbi, zgleda samo ko da se zooma noter na sliko skos */}
				<Box sx={{
					backgroundImage: "url('/images/hospitality/hospitalityBackground2.png')",
					backgroundPosition: 'center',
					// backgroundSize: 'cover',
					backgroundRepeat: 'no-repeat',
					// minHeight: '100vh', // Set the minimum height to cover the full viewport
					height: "1100px",
					width: '100%', // Set the width to cover the full viewport
					maxWidth: "1440px"
				}}>
					<InnerWidthContainer>
						<Stack alignItems={"center"} spacing={4} direction={"row"}
						       sx={{
							       pt: "75px", width: "100%",
							       mb: isMobile ? "26px" : "unset"


						       }}>
							<Typography
								variant={isMobile ? "h3" : "h1"}
								color={theme => theme.palette.background.default}
								sx={{
									mr: isMobile ? "auto" : "unset",
									[theme.breakpoints.down("sm")]: isIos && {
										'@media (-webkit-min-device-pixel-ratio: 2)': {
											fontWeight: 500, // Adjust font weight for iPhones
										},
									},
								}}
							>NK Maribor
								5*</Typography>
							<Button color={"secondary"} variant={"contained"} sx={{borderRadius: "24px"}}>
								<Typography variant={"gumbL"} onClick={() => navigate("common")}>Info</Typography>
							</Button>
						</Stack>
						<Stack alignItems={"center"} sx={{pt: 8}}>
							<Box sx={{display: "flex", alignItems: "flex-start"}}>
								<Box sx={{pt: "12px"}}>
									<svg xmlns="http://www.w3.org/2000/svg" width="93" height="181"
									     viewBox="0 0 93 181"
									     fill="none">
										<path
											d="M92.3333 6C92.3333 3.05447 89.9455 0.666671 87 0.666672C84.0545 0.666672 81.6667 3.05447 81.6667 6C81.6667 8.94553 84.0545 11.3333 87 11.3333C89.9455 11.3333 92.3333 8.94553 92.3333 6ZM6.00002 181L11.8029 171.017L0.255988 170.983L6.00002 181ZM87 5C71.4447 5 58.5207 7.2549 47.8947 12.7549C37.2467 18.2662 28.9916 26.9917 22.7064 39.7721C10.1805 65.2423 5.41807 106.962 5.02657 171.981L7.02654 171.993C7.41829 106.931 12.2049 65.6579 24.5011 40.6547C30.6269 28.1984 38.6011 19.8172 48.814 14.531C59.049 9.23346 71.6176 7 87 7L87 5Z"
											fill="#FECE00"/>
									</svg>
								</Box>
								<Button sx={whiteOutlinedButtonStyle} variant={"outlined"}>
									<Typography variant={"gumbL"} onClick={() => navigate("skybox")}>Zasebne
										lože</Typography>
								</Button>
							</Box>
							<Box sx={{pt: 7, pr: isMobile ? 15 : 27}}>
								<Button sx={whiteOutlinedButtonStyle} variant={"outlined"}
								        onClick={() => navigate("vps")}>
									<Typography variant={"gumbL"}>Premium sedež</Typography>
								</Button>
								<svg xmlns="http://www.w3.org/2000/svg" width="93" height="116" viewBox="0 0 93 116"
								     fill="none">
									<path
										d="M0.666667 110C0.666667 112.946 3.05448 115.333 6 115.333C8.94552 115.333 11.3333 112.946 11.3333 110C11.3333 107.054 8.94552 104.667 6 104.667C3.05448 104.667 0.666667 107.054 0.666667 110ZM87 0L81.1503 9.95564L92.697 10.0438L87 0ZM6 111C36.5854 111 56.9131 105.509 69.6596 89.7692C82.3435 74.1069 87.306 48.5321 87.9313 9.00455L85.9315 8.97292C85.3062 48.5001 80.3174 73.4308 68.1054 88.5105C55.956 103.513 36.4434 109 6 109V111Z"
										fill="#FECE00"/>
								</svg>
							</Box>
						</Stack>
					</InnerWidthContainer>
				</Box>
			</Stack>
		</FullWidthContainer>
	)
}
