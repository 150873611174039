import {useTheme} from "@mui/material";
import {useFooterColorMode, usePaperBackgroundContext} from "@nitra/nkmb-card-components";
import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {VipLanding} from "views/vps/VipLanding";
import VipCommon from "./VipBasic";
import VipSkybox from "./VipSkybox";
import VipVps from "./VipVps";

const VipRouter = () => {
	const theme = useTheme();
	const {setPaperBackgroundColor} = usePaperBackgroundContext();
	const {setIsMonochrome} = useFooterColorMode()

	useEffect(() => {
		const black = "#080814"
		document.body.style.backgroundColor = black;
		setPaperBackgroundColor(black);
		setIsMonochrome(true);
		return () => {
			document.body.style.backgroundColor = theme.palette.background.paper;
			setPaperBackgroundColor(theme.palette.background.paper);
			setIsMonochrome(false);
		}
	}, [])


	return (
		<Routes>
			<Route path={"/"} element={<VipLanding/>}/>
			<Route path={"common"} element={<VipCommon/>}/>
			<Route path={"vps"} element={<VipVps/>}/>
			<Route path={"skybox"} element={<VipSkybox/>}/>
		</Routes>
	)
}

export default VipRouter
