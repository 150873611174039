import {InnerWidthContainer} from "components/widthControlElements";
import {FootballSchoolPlayerListFirestoreDocument, FootballSchoolStaffListFirestoreDocument} from "models";
import React from "react";
import {FootballSchoolPersonGroup} from "views/footballSchool/FootballSchoolPersonGroup";

export function FootballSchoolPersonGrid(props: {
	coaches?: FootballSchoolStaffListFirestoreDocument[],
	players?: FootballSchoolPlayerListFirestoreDocument[]
}) {
	const {coaches, players} = props;

	return (
		<InnerWidthContainer>
			{coaches && <FootballSchoolPersonGroup
                title={"Trenerji"}
                elements={coaches.sort((a, b) => a.position - b.position)}
            />}
			{players && <FootballSchoolPersonGroup
                title={"Igralci"}
                elements={players}
            />}
		</InnerWidthContainer>
	)
}
