import {CardContent, Typography, useTheme} from "@mui/material";
import {CardWithShadow, ShareArea} from "@nitra/nkmb-card-components";
import {useNavigate} from "react-router-dom";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

const LandingPermanentStadiumCard = () => {
	const stadiumImageUrl = "url(/images/landing/ljudski_vrt.png)";
	const navigate = useNavigate()
	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<CardWithShadow
			sx={{
				position: "relative",
				display: "flex",
				flexDirection: "column",
			}}
			onClickSpecialAction={() => navigate("/club/stadium/info")}
			fullSizeImageUrl={stadiumImageUrl}
		>
			<CardContent sx={{mt: "auto", mx: 3, mb: 3, zIndex: 1}}>
				<Typography
					variant={"h3"}
					sx={{color: "background.paper",
						[theme.breakpoints.down("sm")]: isIos && {
							'@media (-webkit-min-device-pixel-ratio: 2)': {
								fontWeight: 500, // Adjust font weight for iPhones
							},
						},
					}}
				>
					Ljudski vrt
				</Typography>
				<Typography
					variant={"bodyS"}
					sx={{color: "background.paper",}}
				>
					srce mojega mesta
				</Typography>
			</CardContent>
			<ShareArea shareUrl={"/stadium"} brightText={true}
			           sx={{position: "absolute", zIndex: 1, right: 24, bottom: 24}}/>
		</CardWithShadow>
	)
}
export default LandingPermanentStadiumCard
