import {Box} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {InnerWidthContainer} from "components/widthControlElements";
import React, {PropsWithChildren, useEffect, useState} from "react";
import {useCompetitionViewContext} from "./common/CompetitionContext";
import {CompetitionTopBar} from "./common/CompetitionTopBar";
import {INNER_MAX_WIDTH} from "../../App";
import {useLocation} from "react-router-dom";
import {CompetitionFilterButtons} from "../../components/CompetitionFilterButtons/CompetitionFilterButtons";
import {
	CompetitionFilterButtonsContextProvider
} from "../../components/CompetitionFilterButtons/CompetitionFilterButtonsContext";

export function CompetitionsLayout(props: PropsWithChildren<{}>) {
	const [seasons, setSeasons] = useState<string[]>([])
	const [showFilterButtons, setShowFilterButtons] = useState(false)

	const {barHeights} = useCompetitionViewContext();
	const isMobile = useIsMobileContext();
	const location = useLocation()


	useEffect(() => {
		const currentYear = new Date().getFullYear()
		const currentMonth = new Date().getMonth()
		const seasons = []
		for (let i = 1991; i <= (currentMonth > 5 ? currentYear : currentYear - 1); i++) {
			seasons.push(`${i}/${i + 1}`)
		}

		setSeasons(seasons)
	}, [])

	useEffect(() => {
		setShowFilterButtons(location.pathname.includes("matches"))
	}, [location])

	return (
		<CompetitionFilterButtonsContextProvider allowMultipleSelection={true}>

			<Box>

				<CompetitionTopBar seasons={seasons}/>
				{showFilterButtons && isMobile &&
                    <Box sx={{
						position: "fixed",
						zIndex: 100,
						top: barHeights.seasonPicker.start + 13,
						right: 8,

					}}>
                        <CompetitionFilterButtons/>
                    </Box>
				}
				<Box
					sx={{
						mt: `${barHeights.tabBar.stop - barHeights.appBar.stop + (isMobile ? 24 : 0)}px`
					}}
				>
					{(isMobile) ? props.children :
						<InnerWidthContainer innerSx={{
							width: "calc(100%-48px)",
							maxWidth: INNER_MAX_WIDTH
						}}>
							{props.children}
						</InnerWidthContainer>
					}
				</Box>
			</Box>
		</CompetitionFilterButtonsContextProvider>

	)
}
