import * as React from "react";
import {useEffect} from "react";
import {VideoFirestoreListDocument} from "../../../models";
import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import {DesktopVideoList} from "./DesktopVideoList";
import {ArrowRight} from "@mui/icons-material";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";
import {NewsSearchLine} from "../NewsSearchLine";
import useNewsSearchContext from "../search/NewsSearchContext";


export function MobileVideoFeed() {
	const [selectedVideo, setSelectedVideo] = React.useState<VideoFirestoreListDocument | null>(null)

	const theme = useTheme()
	const {isIos} = useIosContext()
	const {
		elements
	} = useNewsSearchContext<VideoFirestoreListDocument>()

	useEffect(() => {
		if (elements.length > 0) {
			setSelectedVideo(elements[0])
		} else {
			setSelectedVideo(null)
		}
	}, [elements]);

	return (
		<Stack sx={{color: theme.palette.background.paper, pt: 2}}>
			<NewsSearchLine isOnDarkBackground={true}/>
			<Box sx={{
				width: "100%",
				paddingBottom: "56.25%",
				position: "relative",
				pt: 2
			}}>
				{selectedVideo &&
                    <Box sx={{
						position: "absolute",
						backgroundImage: `url(https://img.youtube.com/vi/${selectedVideo?.youtubeId}/0.jpg)`,
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						width: "100%",
						height: "100%",
					}}>
                        <Typography variant={"h4"} sx={{
							position: "absolute", bottom: 0, px: 2, pb: 2,
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}>
							{selectedVideo?.title}
                        </Typography>
                    </Box>
				}
			</Box>
			<Button
				color={"secondary"}
				variant={"contained"}
				sx={{
					mx: "auto",
					px: 2,
					my: 2,
					borderRadius: 5
				}}
				onClick={() => window.open(`https://www.youtube.com/watch?v=${selectedVideo?.youtubeId}`, "_blank")}
			> <Typography variant={"gumbL"}>Predvajaj vsebino</Typography> <ArrowRight/>
			</Button>
			<DesktopVideoList
				videos={elements}
				setSelectedVideo={setSelectedVideo}
				selectedVideo={selectedVideo}/>
		</Stack>
	)
}