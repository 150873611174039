import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {DesktopCompetitionPlayerStatistics} from "./DesktopCompetitionPlayerStatistics";
import {MobileCompetitionStatistics} from "./MobileCompetitionStatistics";

export function CompetitionPlayerStatistics() {
	const isMobile = useIsMobileContext();

	return isMobile ? <MobileCompetitionStatistics/> : <DesktopCompetitionPlayerStatistics/>
}


