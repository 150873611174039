import {Button, Grid, Typography} from "@mui/material";
import {CardContainerTitle} from "../../../../components/CardContainerTitle";
import React from "react";

export function StadiumPrePurchaseTickets() {
	function handleTyrseva() {
		window.open("https://goo.gl/maps/J3uqQAUjMmYoh6HH8", "_blank")
	}

	function handleMercator() {
		window.open("https://goo.gl/maps/pyyfU9EXBmE43wvYA", "_blank")
	}

	function handleWebshop() {
		window.open("https://www.vijolcnabajta.si/si/vstopnice.html", "_blank")
	}

	return (
		<Grid item xs={12} sx={{mt: 2}}>
			<CardContainerTitle title={"Predprodaja vstopnic"}/>
			<Grid container spacing={1}>
				<Grid xs={12} item><Typography variant={"bodyLBold"}>Vijolčna Bajta</Typography></Grid>
				<Grid xs={12} item>
					<Button onClick={handleTyrseva}>
						<Typography variant={"bodyL"}>Tyrševa ulica 1</Typography>
					</Button>
				</Grid>
				<Grid xs={12} item>
					<Button onClick={handleMercator}>
						<Typography variant={"bodyL"}>Mercator center Tabor II</Typography>
					</Button>
				</Grid>
				<Grid xs={12} item>
					<Button onClick={handleWebshop}>
						<Typography variant={"bodyL"}>Spletni nakup</Typography>
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}
