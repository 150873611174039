import {Box, SxProps, Typography} from "@mui/material";
import {useIsMobileContext} from "@nitra/nkmb-card-components";

/**
 * Title element to be placed above Grid with cards
 * @param props
 * @constructor
 */
export function CardContainerTitle(props: {
	title: string,
	titleAction?: () => void,
	sx?: SxProps,
}) {
	const {title, titleAction} = props;

	const isMobile = useIsMobileContext()

	return (
		<Box
			sx={{
				pt: isMobile ? 2 : 3,
				marginRight: "auto",
				pb: isMobile ? 1 : 3,
				...props.sx
			}}
		>
			<Typography
				sx={{
					cursor: !!titleAction ? "pointer" : "default",
				}}
				variant={isMobile ? "podnaslovXS" : "section"}
				onClick={titleAction}
			>{title}</Typography>
		</Box>
	)
}
