import {CardContent} from "@mui/material";
import {CardFooter, CardOverImageTitle, CardWithShadow} from "@nitra/nkmb-card-components";
import {
	CompetitionHistorySeasonResultListFirestoreDocument
} from "models/firestore/pageCache/CompetitionHistorySeasonResultListFirestoreDocument";
import React from "react";

export function SeasonResultContainer(props: { seasonResult: CompetitionHistorySeasonResultListFirestoreDocument }) {
	const {season, image288x360url, firestoreId} = props.seasonResult

	return (
		<CardWithShadow
			fullSizeImageUrl={image288x360url}
			sx={{
				height: "360px", display: "flex", flexDirection: "column", position: "relative",
			}}
			onClickNavigateUrl={firestoreId}
		>
			<CardContent sx={{mt: "auto", zIndex: 1}}>
				<CardOverImageTitle title={season} variant={"podnaslovM"}/>
				<CardFooter brightText={true}/>
			</CardContent>
		</CardWithShadow>
	)
}
