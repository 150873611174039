import {useIsMobileContext} from "@nitra/nkmb-card-components";
import React from "react";
import {DesktopStatistics} from "views/club/statistics/desktop/DesktopStatistics";
import {MobileStatistics} from "views/club/statistics/mobile/MobileStatistics";

export function ClubStatistics() {
	const isMobile = useIsMobileContext()
	return isMobile ? <MobileStatistics/> : <DesktopStatistics/>
}

