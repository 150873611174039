import {ECompetitionType} from "@nitra/nkmb-card-components";

// TODO move this to nkmb-components
export const getCompetitionNameFromType = (type: ECompetitionType) => {
	switch (type) {
		case ECompetitionType.CUP:
			return "Pokal Slovenije"
		case ECompetitionType.FRIENDLY:
			return "Pripravljalna tekma"
		case ECompetitionType.CHAMPIONS_LEAGUE :
		case ECompetitionType.EUROPA_LEAGUE:
		case ECompetitionType.CONFERENCE_LEAGUE:
			return "UEFA"
		case ECompetitionType.FIRST_LEAGUE:
			return "Prva liga Telemach";
	}
}
