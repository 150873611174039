import {alpha, Stack, SvgIcon, Table, TableCell, TableRow, Typography, useTheme} from "@mui/material";
import React, {useEffect} from "react";
import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {CompetitionStatisticsClubPlayersCombinedRow} from "./CompetitionStatisticsClubPlayersCombinedRow";
import {useIsMobileContext} from "@nitra/nkmb-card-components";
import {NkmbIconKartonpoln} from "@nitra/nkmb-theme";

export function CompetitionStatisticsClubPlayersTable() {
	const {playersData} = useCompetitionStatisticsContext();

	const [borderRadius, setBorderRadius] = React.useState("8px")

	const theme = useTheme()
	const isMobile = useIsMobileContext();

	useEffect(() => {
		setBorderRadius(isMobile ? "0px" : "8px")
	}, [isMobile]);

	return (
		<Table
			sx={{
				borderRadius: borderRadius,
				backgroundColor: theme.palette.background.default,
				boxShadow: isMobile ? "" : "0px 0px 8px 0px #0000001F",
				'& .MuiTableCell-root': {
					py: isMobile ? 0 : 1,
					px: 1,
				},
				mt: 4,
				maxWidth: "912px",
			}}
		>
			<TableRow sx={{
				backgroundColor: isMobile ? alpha(theme.palette.text.primary, 0.07) : theme.palette.background.paper,
				borderTopRadius: borderRadius,
				'& .MuiTableCell-root': {
					py: isMobile ? "2px" : 2,
				},
				color: isMobile ? alpha(theme.palette.text.primary, 0.3) : "#676767",
			}}>
				<TableCell align={"center"} sx={{borderTopLeftRadius: borderRadius}}>
					<Typography variant={"bodySBold"}>{isMobile ? "#" : "Dres"}</Typography>
				</TableCell>
				<TableCell style={{paddingLeft: isMobile ? 16 : "unset"}}><Typography variant={"bodySBold"}>Ime
					Priimek</Typography></TableCell>
				{!isMobile && <TableCell sx={{width: "74px"}}><Typography variant={"bodySBold"}>Igralno
                    mesto</Typography></TableCell>}
				<TableCell align={"center"}><Typography variant={"bodySBold"}>{isMobile ? "Z" : "Zadetki"}</Typography></TableCell>
				<TableCell align={"center"}><Typography
					variant={"bodySBold"}>{isMobile ? "A" : "Asistence"}</Typography></TableCell>
				{isMobile ?
					<TableCell align={"center"} style={{paddingBottom: 0}}>
						<SvgIcon component={NkmbIconKartonpoln}
						         sx={{width: 18, height: 18, color: "#FECE00", pt: "6px", pb: 0}}
						         viewBox={"0 0 100 100"}/>
					</TableCell>
					:
					<TableCell style={{paddingTop: "12px", paddingBottom: 0, width: "60px"}}>
						<Stack sx={{alignItems: "center"}}>
							<Typography variant={"bodySBold"}>Rumeni k. </Typography>
							<Typography variant={"podnapis"}>zadnji karton </Typography>
						</Stack>
					</TableCell>
				}
				{isMobile ?
					<TableCell align={"center"} style={{paddingBottom: 0}}>
						<SvgIcon component={NkmbIconKartonpoln}
						         sx={{width: 18, height: 18, color: "#C20831", pt: "6px", pb: 0}}
						         viewBox={"0 0 100 100"}/>
					</TableCell>
					:
					<TableCell align={"center"} sx={{width: "42px"}}><Typography variant={"bodySBold"}>Rdeči
						k.</Typography></TableCell>
				}

				<TableCell align={"center"}><Typography
					variant={"bodySBold"}>{isMobile ? "M" : "Minute"}</Typography></TableCell>
				{!isMobile &&
                    <TableCell align={"center"}><Typography variant={"bodySBold"}>Nastopi</Typography></TableCell>
				}
				{!isMobile &&
                    <TableCell align={"center"} sx={{borderTopRightRadius: "16px"}}><Typography
                        variant={"bodySBold"}>Starost</Typography></TableCell>
				}
			</TableRow>
			{
				playersData.map((player) => (
					<CompetitionStatisticsClubPlayersCombinedRow key={player.key} player={player}/>
				))
			}
		</Table>
	)
}

