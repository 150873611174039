import {alpha, CardContent, Stack, Typography} from "@mui/material";
import {
	CardOverImageTitle,
	CardWithShadow,
	ShareArea,
	toWeekdayDayAndTime,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {TicketFirestoreDocument} from "models";
import {ETicketLevel} from "models/enums/ETicketLevel";
import React, {useEffect, useState} from "react";
import theme from "@nitra/nkmb-theme";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function MatchTicketCard(props: {
	data: TicketFirestoreDocument
}) {

	const {data} = props;
	const {title, ticketLevel, actionLink} = data

	const isMobile = useIsMobileContext();
	const [imageUrl, setImageUrl] = useState("")
	const [extraText, setExtraText] = useState("")
	const {isIos} = useIosContext()


	useEffect(() => {
		switch (ticketLevel) {
			case ETicketLevel.NORMAL:
				if (isMobile) {
					setImageUrl("/images/tickets_mobile_day_ticket.jpg")
				} else {
					setImageUrl("/images/vstopnice-sedezi.png")
				}
				setExtraText("")
				break;
			case ETicketLevel.VPS:
				if (isMobile) {
					setImageUrl("/images/tickets_mobile_day_vps_ticket.png")
				} else {
					setImageUrl("/images/sezonska-poslovni-sedezi.png")
				}
				setExtraText("Hospitality")
				break;
			case ETicketLevel.SKYBOX:
				setImageUrl("/images/sezonska-privatna-loza.png")
				setExtraText("Zasebna loža")
				break;
		}
	}, [ticketLevel]);

	return (
		<CardWithShadow
			sx={{display: "flex", flexDirection: "column", position: "relative"}}
			cardHeightOverride={isMobile ? 230 : 360}
			fixedWidth={isMobile ? undefined : 288}
			fullSizeImageUrl={imageUrl}
			onClickNavigateUrl={actionLink}
		>
			<CardContent sx={{mt: "auto", zIndex: 1}} style={{paddingBottom: isMobile ? 8 : 16}}>
				<Stack>
					<CardOverImageTitle
						title={title + " " + extraText}
						variant={isMobile ? "h4" : "podnaslovM"}
						sx={{
							pb: isMobile ? 1 : 2,
							[theme.breakpoints.down("sm")]: isIos && {
								'@media (-webkit-min-device-pixel-ratio: 2)': {
									fontWeight: 500, // Adjust font weight for iPhones
								},
							},
						}}
					/>
					<Typography
						sx={{
							color: alpha(theme.palette.background.default, isMobile ? 1.0 : 0.65),
						}}
						variant={isMobile ? "bodyM" : "bodyS"}>{data.date && toWeekdayDayAndTime(data.date)}</Typography>
				</Stack>
				<ShareArea shareUrl={actionLink} brightText={true} sx={{
					position: "absolute",
					right: 16,
					bottom: 16
				}}/>
			</CardContent>
		</CardWithShadow>
	)
}
