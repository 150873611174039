import {useDevelopmentContext} from "@nitra/nkmb-card-components";
import {useBreakpointContext} from "@nitra/nkmb-card-components";


export function DebugBreakpoints() {
	const {showBreakpoint} = useDevelopmentContext();
	const currentBreakpoint = useBreakpointContext();

	return (
		<div style={{position: "absolute", visibility: showBreakpoint ? "visible" : "hidden"}}>
			<span>{currentBreakpoint}</span>
		</div>
	)
}
