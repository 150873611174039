import {Stack} from "@mui/material";
import {ContentContainer} from "components/ContentRender/ContentContainer";
import {getLegend} from "firestore/BaseFirestore";
import {
	ContentElementPageCacheFirebaseDocumentUnion
} from "models/firestore/pageCache/article/ContentElementPageCacheFirebaseDocument";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Banner from "../../../components/Banner";

export function LegendProfile() {
	const [content, setContent] = useState<
		ContentElementPageCacheFirebaseDocumentUnion[]
	>([]);

	const [bannerName, setBannerName] = useState("");
	const [bannerUrl, setBannerUrl] = useState("");

	const {key} = useParams();

	const getData = (_key: string) => {
		getLegend(_key).then((legend) => {
			setContent(legend.content);
			setBannerName(legend.name);
			setBannerUrl(legend.image1440x680url);
		})
	}

	useEffect(() => {
		if (!key) {
			console.error("No key provided");
			return;
		}

		getData(key);
	}, [key])

	return (
		<Stack>
			<Banner title={bannerName} imageUrl={bannerUrl}/>
			<ContentContainer content={content}/>
		</Stack>
	)
}

