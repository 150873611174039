import {collection, doc, getDoc} from "firebase/firestore";
import {db} from "firestore/BaseFirestore";
import {TicketFirestoreDocument} from "models/firestore/ticket/TicketFirestoreDocument";
import {TicketPageCacheFirestoreDocument} from "models/firestore/ticket/TicketPageCacheFirestoreDocument";

export async function getTicketsPage(): Promise<TicketPageCacheFirestoreDocument> {
	const snapshot = await getDoc(doc(collection(db, '/pageCache'), 'tickets'));

	if (!snapshot.exists()) throw new Error('Tickets page not found');

	// TODO item: TicketFirestoreDocument needs to be changed to item: any, and the date transformations changed to item.date.toDate()

	return {
		dayTickets: snapshot.data().dayTickets.map((item: any) => ({
			...item,
			date: item.date?.toDate()
		} as TicketFirestoreDocument)),
		seasonTickets: snapshot.data().seasonTickets.map((item: TicketFirestoreDocument) => ({
			...item,
			date: item.date ? new Date(item.date) : undefined
		} as TicketFirestoreDocument)),
	} as TicketPageCacheFirestoreDocument;
}
