import {Box, Stack} from "@mui/material";
import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import usePlayerStatsContext from "./PlayerStatsContext";
import React from "react";
import {PlayerStatsSelectedSeason} from "./PlayerStatsSelectedSeason";
import {DesktopStatisticsSeasonSelector} from "../../club/statistics/desktop/DesktopStatisticsSeasonSelector";
import {CardContainerTitle} from "../../../components/CardContainerTitle";
import {CompetitionFilterButtons} from "../../../components/CompetitionFilterButtons/CompetitionFilterButtons";
import {PlayerStatsListOfMatches} from "./PlayerStatsListOfMatches";
import theme from "@nitra/nkmb-theme";
import {MobileStatisticsSeasonSelector} from "../../club/statistics/mobile/MobileStatisticsSeasonSelector";
import {SmallLoader} from "../../../components/SmallLoader";

export function PlayerStatTab() {
	const {dataLoaded, seasons, season, setSeason} = usePlayerStatsContext()
	const isMobile = useIsMobileContext()

	if (!dataLoaded) {
		return (
			<Stack sx={{mt: 5}}>
				<SmallLoader isLoading={true}/>
			</Stack>
		)
	}


	return (
		<Box sx={{pt: 4}}>
			<Stack direction={"row"} sx={{alignItems: "center", flexWrap: "wrap"}}>
				<CardContainerTitle
					title={"Statistika sezone"}
					sx={{
						ml: {
							md: 1.5,
							lg: "unset"
						},
						pb: isMobile ? 2 : 3,
					}}
				/>
				{isMobile ? <Stack
						direction={"row"}
						sx={{
							height: "40px",
							backgroundColor: theme.palette.text.primary,
							width: "100vw",
							ml: -2,
							mr: -2,
							px: 2,
							justifyContent: "center",
							alignItems: "center"
						}}>
						<MobileStatisticsSeasonSelector
							isOnPlayers={true} season={season} setSeason={setSeason}
							availableSeasons={seasons}
							sx={{
								mr: "auto",
							}}
						/>
						<CompetitionFilterButtons competitionsOverride={[
							ECompetitionType.FIRST_LEAGUE,
							ECompetitionType.CUP,
							ECompetitionType.ANY_UEFA
						]}/>
					</Stack> :
					<>
						<DesktopStatisticsSeasonSelector isOnPlayers={true} season={season} setSeason={setSeason}
						                                 availableSeasons={seasons}/>
						<CompetitionFilterButtons competitionsOverride={[
							ECompetitionType.FIRST_LEAGUE,
							ECompetitionType.CUP,
							ECompetitionType.ANY_UEFA
						]}/>
					</>
				}
			</Stack>
			<PlayerStatsSelectedSeason/>
			<PlayerStatsListOfMatches/>
		</Box>
	)
}

