import {Stack} from "@mui/material";
import {Redirect} from "@nitra/nkmb-card-components";
import {INavigationBarTab} from "components/NavigationBar/INavigationBarTab";
import {NavigationBar} from "components/NavigationBar/NavigationBar";
import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {HighlightsFeed} from "views/news/HighlightsFeed";
import {MatchReportFeed} from "views/news/MatchReportFeed";
import {VideoFeed} from "views/news/VideoFeed";
import NewsArticle from "./article/NewsArticle";
import {NewsFeedContainer} from "./NewsFeedContainer";


const tabs: INavigationBarTab[] = [
	{label: "Članki", url: "feed"},
	{label: "Video prispevki", url: "video"},
	// {label: "Vrhunci", url: "highlights"},
	// {label: "Poročila s tekem", url: "matchReports"},
]

export function NewsRouter() {
	return (
		<Stack>
			<NavigationBar tabs={tabs}/>
			<Routes>
				<Route path={"/"} element={<Redirect to={"feed"}/>}/>
				<Route path={"feed"} element={<NewsFeedContainer/>}/>
				<Route path={"video"} element={<VideoFeed/>}/>
				<Route path={"highlights"} element={<HighlightsFeed/>}/>
				<Route path={"matchReports"} element={<MatchReportFeed/>}/>
				<Route path={"/:id"} element={<NewsArticle/>}/>
			</Routes>
		</Stack>
	)
}

