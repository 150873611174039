import {ECompetitionType, useIsMobileContext} from "@nitra/nkmb-card-components";
import {Box, createTheme, Stack} from "@mui/material";
import React from "react";
import {ThemeProvider} from "@mui/system";
import theme from "@nitra/nkmb-theme";
import {CompetitionFilterButton} from "./CompetitionFilterButton";

const blackTheme = createTheme(theme, {
	palette: {
		primary: {
			main: theme.palette.text.primary,
			dark: `rgba(18, 18, 18, 0.2)`,
		},
		action: {
			disabledBackground: theme.palette.text.primary,
			disabled: theme.palette.background.default
		}
	},
})

export function CompetitionFilterButtons(props: {
	competitionsOverride?: ECompetitionType[]
}) {
	const {competitionsOverride} = props

	const competitions = competitionsOverride ?? [
		ECompetitionType.FIRST_LEAGUE,
		ECompetitionType.CUP,
		ECompetitionType.ANY_UEFA,
		ECompetitionType.FRIENDLY
	]

	const isMobile = useIsMobileContext()


	return isMobile ? (
		<Stack direction={"row"}
		       alignItems={"center"}>
			{competitions?.map((competition, index) =>
				<>
					<CompetitionFilterButton
						key={competition}
						competition={competition}
					/>
					{index < competitions.length - 1 &&
                        <Box sx={{height: "16px", color: "#676767", border: "1px solid", mx: 1}}/>}
				</>
			)}
		</Stack>
	) : (
		<ThemeProvider theme={blackTheme}>
			<Stack direction={"row"} spacing={1} sx={{pl: isMobile ? 2 : 0}} alignItems={"center"}>
				{competitions?.map(competition =>
					<CompetitionFilterButton
						key={competition}
						competition={competition}
					/>
				)}
			</Stack>
		</ThemeProvider>
	)
}


