import useCompetitionStatisticsContext from "../common/CompetitionStatisticsContext";
import {CardContent, Stack, Typography} from "@mui/material";
import {SingleMatchContainer} from "../../../components/Competitions/SingleMatchContainer";
import {CompetitionStatisticsStreaks} from "./CompetitionStatisticsStreaks";
import {CardWithShadow} from "@nitra/nkmb-card-components";
import {CompetitionStatisticsClubStatsBlock} from "./CompetitionStatisticsClubStatsBlock";
import React from "react";

export function DesktopCompetitionClubStatistics() {
	const {seasonNumber, competitionStatBlock} = useCompetitionStatisticsContext();

	return (
		<Stack sx={{pt: 4}}>
			<Typography sx={{pb: 5}} variant={"podnaslovM"}>Statistika kluba
				sezona {seasonNumber}/{seasonNumber + 1}</Typography>
			<Typography sx={{pb: 3, color: "#676767"}} variant={"podnaslovS"}>najvišji rezultati</Typography>
			<Stack direction={"row"} sx={{width: "100%"}}>
				<Stack sx={{flexGrow: 1, pr: 2}}>
					{competitionStatBlock &&
                        <Stack spacing={3}>
							{competitionStatBlock.highestWins.map(match => (
								<SingleMatchContainer key={match.key} match={match}/>
							))}
                        </Stack>
					}
					<CompetitionStatisticsStreaks/>
				</Stack>
				<CardWithShadow sx={{width: "288px", height: "unset", minHeight: "597px", ml: "auto", mb: "auto"}}
				                disableOnHoverActions={true}>
					<CardContent
						sx={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}
						style={{
							padding: "0", // Style is used instead of sx since theme settings override this otherwise
						}}
					>
						<CompetitionStatisticsClubStatsBlock/>
					</CardContent>
				</CardWithShadow>
			</Stack>
		</Stack>
	)
}