import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import {Stack, Typography} from "@mui/material";
import {TextWidthContainer} from "../../components/widthControlElements";

export function FootballSchoolLandingBody() {
	return (
		<TextWidthContainer>
			<Stack direction={"row"} sx={{justifyContent: "space-between", width: "100%", pt: 4}}>
				<Stack sx={{justifyContent: "space-between"}}>
					<Stack direction={"row"}>
						<CallIcon/>
						<Typography> 02/620 97 05</Typography>
					</Stack>

					<Stack direction={"row"}>
						<EmailIcon/>
						<Typography>nogometna.sola@nkmaribor.com</Typography>
					</Stack>
				</Stack>
				<Stack>
					<Typography>
						Nogometna šola NK Maribor<br/>
						Mladinska ulica 29<br/>
						2000 Maribor
					</Typography>
				</Stack>
			</Stack>
			<Typography sx={{pt: 4}}>
				Za nami je bogata preteklost, uspešna sedanjost, toda ne pozabimo na prihodnost. Da so otroci naše
				največje bogastvo, se zavedamo tudi pri NK Maribor. Nogometna šola je nadvse pomemben del celotnega
				mozaika. Zavedamo se, da le strokovno delo in prava organizacija lahko zagotavljata zanimanje za nogomet
				med najmlajšimi. Naš namen je, da jih navdušimo za nogometno igro, spodbudimo h gibanju in k zdravemu
				načinu življenja. Želimo jim omogočiti boljše možnosti za razvoj, zato konstantno dvigujemo nivo
				organiziranosti in stremimo k profesionalizaciji strokovnega kadra.
			</Typography>

			<Typography>
				<br/>
				Nogometna šola našega kluba je skozi vsa leta delovanja v svojih vrstah vzgojila fante, ki ponosno
				predstavljajo nogomet in naše mesto po Sloveniji ter v tujini. Prav uspehi, ki jih dosega naše člansko
				moštvo in ekipe nogometne šole ter tudi posamezniki, vzgojeni pri nas, nam dajejo vedno znova zagon za
				še
				bolj kvalitetno in zagnano delo. Hkrati pa med delovanjem šole ne pozabljamo na njen širši socialni
				pomen:
				otroci se pri nas ne učijo le nogometnih veščin, ampak jih strokovni delavci pripravljajo tudi na
				nadaljnje
				življenje. Spoznavajo pomen discipline, skupinskega dela, medsebojnega sodelovanja, omogočena pa so jim
				tudi
				potovanja po Sloveniji in Evropi.
				<br/><br/>
				Po tekmovalni plati je nogometna šola NK Maribor v zadnjih letih med najuspešnejšimi v Sloveniji. Mladi
				nogometaši so razdeljeni v deset starostnih selekcij od U-8 do U19, v katerih je registriranih več kot
				210
				igralcev. Od ustanovitve šole v sedanji obliki (leta 1990) so naše vitrine napolnile številne trofeje,
				namenjene zmagovalcu državnega prvenstva in pokalnega tekmovanja. Le mariborskim upom je doslej uspelo v
				eni
				sezoni osvojiti naslove prvaka v kategorijah U-13, U-15, U-17 in U-19. Z dosežki in s sodelovanjem na
				številnih mednarodnih turnirjih so prispevali velik delež k prepoznavnosti imena nogometne šole NK
				Maribor v
				tujini.
				<br/><br/>
				Kljub športnemu motivu po doseganju želenih športnih rezultatov se v nogometni šoli zavedamo, da je na
				prvem
				mestu izobrazba. Tako so lahko člani vseh selekcij zgolj otroci, ki šole ne postavljajo na stranski tir
				in
				vestno izpolnjujejo šolske obveznosti. Vzoren primer predstavljajo tudi nekateri igralci članskega
				moštva,
				ki združujejo profesionalne napore s študijem in so s svojim načinom življenja vzorniki mladim
				nogometašem.
				<br/><br/>
				Uspešnost dela v nogometni šoli pa je mogoče oceniti tudi na podlagi mladih nogometašev, ki so dobili
				povabilo za igranje v državnih reprezentancah in ponudbe uveljavljenih evropskih klubov.
				<br/><br/>
				Vsak dan stremimo k temu da smo boljši in uspešnejši, ampak zavedamo se, da brez trdega dela,
				inovativnosti
				in sledenju smernic sodobnega nogometa ne gre. Prav zato želimo organizirati nogometno šolo, tako da
				bomo
				vzor drugim šolam v naši regiji, Sloveniji in tem delu Evrope.
			</Typography>
			<Typography variant={"podnaslovS"}>Sebastijan Harc</Typography>
			<Typography>Direktor nogometne šole NK Maribor</Typography>
		</TextWidthContainer>
	)
}
