import {
	ArticleListFirestoreDocument,
	DoubleVideoCard,
	DynamicNewsCard,
	useIsMobileContext
} from "@nitra/nkmb-card-components";
import {CardContainerWithName} from "components/CardContainerWithName";
import {InnerWidthContainer} from "components/widthControlElements";
import React from "react";
import {useNavigate} from "react-router-dom";
import {VideoFirestoreListDocument} from "../../models/firestore/pageCache/VideoFirestoreListDocument";

export function LandingNewsContainer(props: {
	items: ArticleListFirestoreDocument[],
	video?: VideoFirestoreListDocument,
}) {
	const {items, video} = props;
	const navigate = useNavigate();
	const isMobile = useIsMobileContext();

	return (
		<InnerWidthContainer>
			<CardContainerWithName
				title={"Zadnje novice"}
				titleClickAction={() => navigate("/news")}
			>
				{items.map((item, index) =>
					<DynamicNewsCard
						key={item.firestoreId}
						data={item}
						index={index}
						isOnLanding={true}/>
				)}
				{video && <DoubleVideoCard sx={{height: isMobile ? "unset" : "198px", width: isMobile ? "100%" : "288px"}} youTubeId={video.youtubeId}/>}
			</CardContainerWithName>
		</InnerWidthContainer>
	)
}
