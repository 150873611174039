import {Stack, Typography, useTheme} from "@mui/material";
import usePlayerDetailsContext from "views/team/playerDetailsPage/PlayerDetailsContext";

export function PlayerProfileBannerLeftColumnDesktop() {
	const player = usePlayerDetailsContext();
	const theme = useTheme();

	const {jerseyNumber, firstName, lastName} = player!;

	return (
		<Stack sx={{
			pl: 10,
			height: "100%",
			color: theme.palette.primary.main
		}}>
			<Typography
				variant={"h1"}
				sx={{pt: "186px", fontSize: "150px"}}
			>{jerseyNumber}</Typography>
			<Stack sx={{pb: 7, mt: "auto"}}>
				<Typography
					variant={"h4"}
					sx={{pb: 3}}
				>{firstName}</Typography>
				<Typography
					variant={"h1"}
					sx={{fontSize: "102px"}}
				>{lastName}</Typography>
			</Stack>
		</Stack>
	)
}
