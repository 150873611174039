import {Box, Stack, Typography} from "@mui/material";
import {InnerWidthContainer} from "components/widthControlElements";
import {getClubStatistics} from "firestore/specificGetters/StatisticsFirestore";

import {ClubStatisticsMatchFirestoreDocument, ClubStatisticsSeasonStandingFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";
import {DesktopClubStatisticsMatchList} from "views/club/statistics/desktop/club/DesktopClubStatisticsMatchList";
import {
	DesktopClubStatisticsSelectedMatchContainer
} from "views/club/statistics/desktop/club/DesktopClubStatisticsSelectedMatchContainer";
import {DesktopClubStatisticsStandings} from "views/club/statistics/desktop/club/DesktopClubStatisticsStandings";
import {DesktopStatisticsSeasonSelector} from "views/club/statistics/desktop/DesktopStatisticsSeasonSelector";


export function DesktopClubStatistics(props: {}) {
	const [name, setName] = useState("");
	const [standings, setStandings] = useState<ClubStatisticsSeasonStandingFirestoreDocument[]>([])
	const [matches, setMatches] = useState<ClubStatisticsMatchFirestoreDocument[]>([])
	const [selectedMatch, setSelectedMatch] = useState<ClubStatisticsMatchFirestoreDocument | null>(null)
	const [season, setSeason] = useState(1960)


	useEffect(() => {
		get()
	}, [season]);

	async function get() {
		const payload = await getClubStatistics(season)

		setName(payload.name)
		setStandings(payload.standings)
		setMatches(payload.matches)
		setSelectedMatch(payload.matches[0])
	}

	return (
		<Stack>
			<InnerWidthContainer>
				<DesktopStatisticsSeasonSelector isOnPlayers={false} season={season} setSeason={setSeason}/>
				<Box sx={{py: 2, pl: 0.5}}>
					<Typography variant={"podnaslovL"}>{name} - sezona {season}/{season + 1}</Typography>
				</Box>
				<DesktopClubStatisticsStandings standings={standings}/>
				<Stack direction={"row"} sx={{pt: 4}} alignItems={"flex-start"}>
					<DesktopClubStatisticsMatchList matches={matches}
					                                season={season}
					                                setSelectedMatch={setSelectedMatch}/>
					<DesktopClubStatisticsSelectedMatchContainer selectedMatch={selectedMatch} season={season}/>
				</Stack>
			</InnerWidthContainer>
		</Stack>
	)
}

