import {Stack} from "@mui/material";
import {Redirect} from "@nitra/nkmb-card-components";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {ClubStadiumHistory} from "./history/ClubStadiumHistory";
import {ClubStadiumInfo} from "./info/ClubStadiumInfo";
import {ClubStadiumLegendaryMatch} from "./legendaryMatches/ClubStadiumLegendaryMatch";
import {ClubStadiumLegendaryMatches} from "./legendaryMatches/ClubStadiumLegendaryMatches";

export function ClubStadiumRouter() {
	return (
		<Stack>
			<Routes>
				<Route path={"/"} element={<Redirect to={"info"}/>}/>
				<Route path="info" element={<ClubStadiumInfo/>}/>
				<Route path="stadiumHistory" element={<ClubStadiumHistory/>}/>
				<Route path="legendaryMatches" element={<ClubStadiumLegendaryMatches/>}/>
				<Route path={"legendaryMatches/:key"} element={<ClubStadiumLegendaryMatch/>}/>
			</Routes>
		</Stack>
	);
}

