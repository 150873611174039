import {useNavigate} from "react-router-dom";
import {Stack, Typography, useTheme} from "@mui/material";
import useIosContext from "@nitra/nkmb-card-components/contexts/IosContextProvider";

export function MobileNextArticle(props: {
	data: {
		title: string,
		firestoreId: string
		image104x141url: string,
	}
}) {
	const {title, firestoreId, image104x141url} = props.data;
	const navigate = useNavigate()

	const {isIos} = useIosContext()
	const theme = useTheme();

	return (
		<Stack spacing={1} onClick={() => navigate(`../${firestoreId}`)}
		       sx={{p: 2, mx: "-16px"}}>
			<Typography variant={"gumbL"}>Naslednji članek</Typography>
			<Typography variant={"h4"} sx={{
				[theme.breakpoints.down("sm")]: isIos && {
					'@media (-webkit-min-device-pixel-ratio: 2)': {
						fontWeight: 500, // Adjust font weight for iPhones
					},
				},
			}}>{title}</Typography>
			<div
				style={{
					width: "100%",
					height: 199,
					borderRadius: "8px",
					backgroundImage: `url(${image104x141url})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat'

				}}
			/>
		</Stack>
	)
}