import {useTheme} from "@mui/material";
import {DataGrid, GridColumns, GridSelectionModel} from "@mui/x-data-grid";
import typography from "@nitra/nkmb-theme/typography";
import {ClubStatisticsMatchFirestoreDocument} from "models";
import React, {useEffect, useState} from "react";

export function DesktopClubStatisticsMatchList(props: {
	matches: ClubStatisticsMatchFirestoreDocument[],
	setSelectedMatch: (match: ClubStatisticsMatchFirestoreDocument | null) => void,
	season: number
}) {
	const {matches, setSelectedMatch, season} = props;
	const [selected, setSelected] = useState<GridSelectionModel>()

	const theme = useTheme();

	useEffect(() => {
		if (selected && selected.length === 1) {
			setSelectedMatch(matches.find(match => match.round + match.homeName + match.awayName === selected[0])!)
		} else {
			setSelectedMatch(null)
		}
	}, [selected]);

	useEffect(() => {
		if (matches.length > 0) {
			setSelected([matches[0].round + matches[0].homeName + matches[0].awayName])
		} else {
			setSelected([])
		}
	}, [matches]);

	const columns: GridColumns<ClubStatisticsMatchFirestoreDocument> = [
		{
			field: "round", headerName: "Krog",
			width: 30,
			minWidth: 30,
			flex: 0,
			align: "center",
		},
		{
			field: "resultFirstHalf", headerName: "Sezona", renderCell: () => `${season}/${season - 1899}`,
			width: 52,
			minWidth: 10,
			align: "center",
		},
		{
			field: "homeName",
			headerName: "tekma",
			flex: 1,
			renderCell: (params: { row: ClubStatisticsMatchFirestoreDocument }) =>
				<span>{params.row.homeName} - {params.row.awayName}</span>
		},
		{
			field: "result", headerName: "Rezultat",
			width: 30,
			minWidth: 10,
			align: "center"
		}
	]


	return (
		<DataGrid columns={columns}
		          rows={matches}
		          getRowId={(row) => row.round + row.homeName + row.awayName}
		          hideFooterPagination={true}
		          density={"compact"}
		          hideFooter={true}
		          autoHeight={true}
		          disableColumnMenu={true}
		          selectionModel={selected}
		          onSelectionModelChange={(newSelection) => {
			          setSelected(newSelection)
		          }}
		          sx={{
			          "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
				          display: "none",
			          },
			          "& .MuiDataGrid-virtualScroller": {
				          marginTop: "0 !important",
			          },
			          '& .MuiDataGrid-cell': {
				          ...typography.podnapis,
				          px: 1,
			          },
			          '& .MuiDataGrid-row.Mui-selected': {
				          backgroundColor: "#676767",
				          color: theme.palette.background.default,
			          },
			          backgroundColor: theme.palette.background.default,
			          borderRadius: 1,
			          width: 288,
			          minWidth: 288,
			          maxWidth: 288,
			          '& .MuiDataGrid-cell:focus': {
				          outline: "0 !important",
			          },
		          }}
		/>
	)
}
