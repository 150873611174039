import {Typography, useTheme} from "@mui/material";
import {CardWithShadow} from "@nitra/nkmb-card-components";

export function DesktopSuggestedArticleCard(props: {
	data: {
		title: string,
		firestoreId: string,
		image600x360url: string,
	}
}) {
	const {title, image600x360url, firestoreId} = props.data;
	const theme = useTheme();

	return (
		<CardWithShadow
			onClickNavigateUrl={`../${firestoreId}`}
			fullSizeImageUrl={`url(${image600x360url})`}
			fixedWidth={492}
			cardHeightOverride={278}
			sx={{position: "relative"}}
		>
			<Typography sx={{
				position: "absolute",
				left: 24,
				bottom: 16,
				color: theme.palette.background.default
			}} variant={"h4"}>{title}</Typography>
		</CardWithShadow>
	)
}