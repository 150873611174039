import {ThemeProvider} from "@mui/system";
import theme from "@nitra/nkmb-theme";
import {DebugTypographies} from "components/AppBar/DebugTypographies";
import {ContextProviders} from "components/ContextProviders/ContextProviders";
import * as React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {ClubRouter} from "views/club/ClubRouter";
import {CompetitionsRouter} from "views/competitions/CompetitionsRouter";
import {FootballSchoolRouter} from "views/footballSchool/FootballSchoolRouter";
import {MoreRouter} from "views/more/MoreRouter";
import {NewsRouter} from "views/news/NewsRouter";
import "./App.css"
import CommonLayout from "./components/CommonLayout";
import Landing from "./views/Landing";
import TeamRouter from "./views/team/TeamRouter";
import TicketRouter from "./views/tickets/TicketRouter";
import VipRouter from "./views/vps/VipRouter";


export const INNER_MAX_WIDTH = "1224px";
export const INNER_PADDING = {
	xs: 2,
	xl: 2,
}

function App() {
	return (
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<ContextProviders>
					<CommonLayout>
						<Routes>
							<Route path={"/"}>
								<Route path={"/"} element={<Landing/>}/>
								<Route path={"news/*"} element={<NewsRouter/>}/>
								<Route path={"tickets/*"} element={<TicketRouter/>}/>
								<Route path={"competitions/*"} element={<CompetitionsRouter/>}/>
								<Route path={"hospitality/*"} element={<VipRouter/>}/>
								<Route path={"team/*"} element={<TeamRouter/>}/>
								<Route path={"footballSchool/*"} element={<FootballSchoolRouter/>}/>
								<Route path={"club/*"} element={<ClubRouter/>}/>
								<Route path={"more/*"} element={<MoreRouter/>}/>
								<Route path={"fonts"} element={<DebugTypographies />}/>
							</Route>
						</Routes>
					</CommonLayout>
				</ContextProviders>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
